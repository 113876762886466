import { clsx } from 'clsx'
import i18next from 'i18next'
import type { ReactNode } from 'react'
import ReactModal from 'react-modal'
import type { PanelSize } from './panel'

interface Props {
    title?: string | ReactNode
    showColor?: boolean
    isOpen: boolean
    bottomAlignment?: 'left' | 'center' | 'right'
    confirmDisabled?: boolean
    cancelDisabled?: boolean
    overflowHidden?: boolean
    children?: ReactNode
    bottomChildren?: ReactNode
    className?: string
    disableSize?: boolean
    size?: PanelSize
    padding?: number
    onRequestClose: () => void
    cancelLabel?: string
    onCancel?: () => void
    okLabel?: string
    onConfirm?: () => void
    maxHeight?: string
    height?: string
    overlayClassName?: string
}

const Modal = ({
    showColor = true,
    title,
    size = '1/3',
    isOpen,
    confirmDisabled = false,
    cancelDisabled = false,
    children,
    bottomChildren,
    onRequestClose,
    bottomAlignment = 'left',
    onCancel,
    onConfirm,
    okLabel = i18next.t('web_ok'),
    cancelLabel = i18next.t('web_cancel'),
    padding = 8,
    overflowHidden = true,
    className = '',
    disableSize = false,
    height = 'h-auto',
    maxHeight = '75%',
    overlayClassName,
}: Props) => {
    const classSize = disableSize ? '' : (`w-full md:w-${size}` as PanelSize)
    const paddingSize = `p-${padding}` as
        | 'p-0'
        | 'p-0.5'
        | 'p-1'
        | 'p-1.5'
        | 'p-2'
        | 'p-2.5'
        | 'p-3'
        | 'p-3.5'
        | 'p-4'
        | 'p-5'
        | 'p-6'
        | 'p-7'
        | 'p-8'
        | 'p-9'
        | 'p-10'
        | 'p-11'
        | 'p-12'
        | 'p-14'
        | 'p-16'
        | 'p-20'
        | 'p-24'
        | 'p-28'
        | 'p-32'
        | 'p-36'
        | 'p-40'
        | 'p-44'
        | 'p-48'
        | 'p-52'
        | 'p-56'
        | 'p-60'
        | 'p-64'
        | 'p-72'
        | 'p-80'
        | 'p-96'

    const alignment = {
        left: 'justify-between',
        center: 'justify-center',
        right: 'justify-end',
    }[bottomAlignment]

    return (
        <ReactModal
            isOpen={isOpen}
            closeTimeoutMS={200}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick
            overlayClassName={clsx(
                'fixed inset-0 flex items-center justify-center bg-black/25 transition-all duration-500 ease-in-out z-40',
                overlayClassName
            )}
            className={clsx(
                classSize,
                height,
                'rounded-lg font-nunito shadow-lg transition duration-500 ease-in-out',
                paddingSize,
                className,
                {
                    'bg-white': showColor,
                    'overflow-y-auto': overflowHidden,
                }
            )}
            style={{
                overlay: {},
                content: {
                    maxHeight,
                },
            }}
        >
            <div className={`flex flex-col items-center ${overflowHidden ? 'overflow-auto' : ''} h-full`}>
                {title &&
                    (typeof title === 'string' ? (
                        <h4 className="mb-6 text-center font-nunito text-xl font-bold">{title}</h4>
                    ) : (
                        title
                    ))}
                {children}

                {(onCancel || onConfirm || bottomChildren) && (
                    <div className={`mb-2 mt-6 flex w-full space-x-2 px-4 ${alignment}`}>
                        {bottomChildren}
                        {onCancel && (
                            <button
                                type="button"
                                className="btn white"
                                disabled={cancelDisabled}
                                onClick={() => onCancel()}
                            >
                                {cancelLabel}
                            </button>
                        )}
                        {onConfirm && (
                            <button
                                type="button"
                                className="btn"
                                disabled={confirmDisabled}
                                onClick={() => onConfirm()}
                            >
                                {okLabel}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </ReactModal>
    )
}

export default Modal
