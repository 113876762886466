import { faUserSecret } from '@fortawesome/pro-regular-svg-icons'
import {
    faCheck,
    faChevronsUp,
    faEdit,
    faEnvelope,
    faLock,
    faSearch,
    faSpinner,
    faTrash,
    faUserUnlock,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import Admin from 'components/admin/admin'
import { getTypes } from 'components/admin/users/form'
import { getFileSize } from 'components/dataroom/upload/file-size'
import { selectStyles } from 'components/shared/select-styles'
import SmallLoader from 'components/shared/small-loader'
import Tooltip from 'components/shared/tooltip'
import { del, get, post } from 'core/services/http-service'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { hasBrandAccess } from 'stores/brands'
import { useMst } from 'stores/store'
import Modal from '../../../components/shared/modal'

interface Data {
    users: {
        uuid: string
        email: string
        fullname: string
        firstname: string
        lastname: string
        locale: string
        role: 'FRANCHISEE' | 'AGENT' | 'ADMIN' | 'CONTROLLER'
        type: 'NATURAL' | 'LEGAL'
        enabled: boolean
        secondaryEmail: boolean
        readOnly: boolean
        canManagePersonalData: boolean
        storage: {
            max: number
            current: number
            percent: number
            totalFiles: number
            theStackFiles: number
            sortedFilesCount: number
            sorted: number
            toSort: number
            preSortedCount: number
            preSorted: number
            quarantineCount: number
            quarantine: number
            sharedCount: number
            shared: number
            compressedCount: number
            compressed: number
        }
    }[]
    nextCursor?: number
}

type DataUser = Pick<Data, 'users'>['users'][number]

const Users = () => {
    const { user } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()

    const [search, setSearch] = useState<string>()
    const [gotoTopButton, showGotoTopButton] = useState<boolean>(false)

    const navigate = useNavigate()

    const names = user.currentFranchise.getConfig().roles

    const impersonate = useCallback(async (userId: string) => {
        await user.brandImpersonate(id, userId)
        navigate('/')
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            showGotoTopButton(window.scrollY > 0)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const fetchUsers = async ({ pageParam = 0 }) => {
        const { data } = await get<{ cursor: number; search?: string }, { data: Data }>(
            `/v1/web/franchises/${id}/users`,
            {
                cursor: pageParam,
                search,
            }
        )

        return data
    }

    const readOnlyDurations = [90, 60, 30].map(duration => ({ value: duration, label: duration }))
    const readOnlyDefaultAccessDuration = 90
    type ReadOnlyAccessDuration = 30 | 60 | 90

    const queryClient = useQueryClient()

    const { data, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteQuery({
        queryKey: ['franchises.users'],
        queryFn: fetchUsers,
        initialPageParam: null,
        getNextPageParam: lastPage => lastPage.nextCursor,
    })

    const [readOnlyUser, setReadOnlyUser] = useState<DataUser>()
    const [readOnlyAccessDuration, setReadOnlyAccessDuration] = useState<ReadOnlyAccessDuration>(30)
    const [readOnlyProcessing, setReadOnlyProcessing] = useState<boolean>(false)
    const [readOnlyModal, showReadOnlyModal] = useState<boolean>(false)
    const openReadOnlyModal = (user: DataUser) => {
        setReadOnlyUser(user)
        showReadOnlyModal(true)
    }

    const types = getTypes(t)

    const flagReadOnly = async (user: DataUser) => {
        setReadOnlyProcessing(true)

        // TODO handle error
        try {
            const res = await post<{ accessDuration: ReadOnlyAccessDuration }, { success: boolean }>(
                `/v1/web/franchises/${id}/users/${user.uuid}/read-only`,
                {
                    accessDuration: readOnlyAccessDuration,
                }
            )

            if (res.success) {
                toast.success(t('web_read_only_success'))
                showReadOnlyModal(false)
                setReadOnlyUser(undefined)
                refetch()
            }
        } catch (error) {
            toast.error(t('web_read_only_error_occurred'))
        }

        setReadOnlyProcessing(false)
    }

    useEffect(() => {
        queryClient.clear()
        refetch()
    }, [id])

    const [showDelete, setShowDelete] = useState<boolean>(false)
    useEffect(() => {
        setShowDelete(user.isAdmin)
    }, [user])

    const deleteUser = async (user: DataUser) => {
        await del(`/v1/web/franchises/${id}/users/${user.uuid}`)
        refetch()
        toast.success(t('web_admin_franchise_user_deleted'))
    }

    return (
        <Admin
            title={t('web_admin_users')}
            isLoading={isFetching}
            header={
                <div className="flex items-center space-x-4">
                    {hasBrandAccess(user, 'admin_can_add_users') && (
                        <Link to={`/franchises/${id}/users/create`} className="btn">
                            {t('web_admin_users_new')}
                        </Link>
                    )}

                    <div className="flex items-center gap-1 rounded border bg-white px-4 py-1">
                        <FontAwesomeIcon icon={faSearch} size="sm" className="text-gray-500" />
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setSearch(e.currentTarget.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    refetch()
                                }
                            }}
                        />
                    </div>
                </div>
            }
        >
            {(data?.pages ?? []).flatMap(p => p.users).length > 0 ? (
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    loader={<FontAwesomeIcon icon={faSpinner} spin key={0} />}
                >
                    <table className="admin w-full table-auto">
                        <thead>
                            <tr>
                                <th className="text-left">{t('web_franchise_users_name')}</th>
                                <th className="text-left">{t('web_franchise_users_email')}</th>
                                <th className="text-left">{t('web_franchise_users_storage')}</th>
                                <th className="text-left">{t('web_franchise_users_enabled')}</th>
                                <th className="text-left">{t('web_franchise_users_secondary_email')}</th>
                                <th className="text-left">{t('web_franchise_users_role')}</th>
                                <th className="text-left">{t('web_franchise_users_type')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {(data?.pages ?? [])
                                .flatMap(p => p.users)
                                .filter((item, index, self) => self.findIndex(i => i.uuid === item.uuid) === index)
                                .map(item => {
                                    const makeReadOnlyAvailable = item.secondaryEmail && !item.readOnly

                                    return (
                                        <tr key={item.uuid}>
                                            <td>{item.fullname}</td>
                                            <td>{item.email}</td>
                                            <td>{getFileSize(t, item.storage.current)}</td>
                                            <td>
                                                {item.readOnly && !item.canManagePersonalData ? (
                                                    <Tippy content={t('web_api_read_only_blocked')}>
                                                        <FontAwesomeIcon icon={faLock} />
                                                    </Tippy>
                                                ) : item.readOnly ? (
                                                    <Tippy content={t('web_api_read_only')}>
                                                        <FontAwesomeIcon icon={faUserUnlock} />
                                                    </Tippy>
                                                ) : (
                                                    <Tippy content={t('web_api_user_valid')}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </Tippy>
                                                )}
                                            </td>
                                            <td>
                                                {item.secondaryEmail ? (
                                                    <FontAwesomeIcon icon={faCheck} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faXmark} />
                                                )}
                                            </td>
                                            <td>
                                                {names?.[item.role.toLowerCase()] ??
                                                    t(`web_franchise_users_role_${item.role.toLowerCase()}`)}
                                            </td>
                                            <td>{types.find(type => type.value === item.type)?.label}</td>
                                            <td className="flex items-center justify-end gap-1 text-christine">
                                                {item.uuid !== user.uuid && (
                                                    <Tooltip tooltip={t('web_franchise_users_impersonate')}>
                                                        <button type="button" onClick={() => impersonate(item.uuid)}>
                                                            <FontAwesomeIcon icon={faUserSecret} />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {hasBrandAccess(user, 'admin_can_add_users') && (
                                                    <Link to={`/franchises/${id}/users/${item.uuid}`}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Link>
                                                )}

                                                {hasBrandAccess(user, 'admin_can_add_users') && (
                                                    <Tooltip tooltip={t('web_admin_user_send_recovery_tooltip')}>
                                                        <button
                                                            type="button"
                                                            onClick={async () => {
                                                                if (confirm(t('web_admin_user_send_recovery'))) {
                                                                    try {
                                                                        await user.sendRecoveryEmail(item.uuid)
                                                                        toast.success(t('web_admin_user_recovery_sent'))
                                                                    } catch (error) {
                                                                        toast.error(error.message ?? error)
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faEnvelope} />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {hasBrandAccess(user, 'admin_can_add_users') && (
                                                    <Tippy
                                                        content={t(
                                                            item.readOnly
                                                                ? 'web_user_already_read_only'
                                                                : makeReadOnlyAvailable
                                                                  ? 'web_user_make_read_only'
                                                                  : 'web_user_read_only_no_secondary'
                                                        )}
                                                    >
                                                        {makeReadOnlyAvailable ? (
                                                            <button
                                                                disabled={!makeReadOnlyAvailable}
                                                                onClick={() => openReadOnlyModal(item)}
                                                            >
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </button>
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faLock}
                                                                className={clsx(
                                                                    item.readOnly
                                                                        ? 'text-christine'
                                                                        : 'text-christine/25'
                                                                )}
                                                            />
                                                        )}
                                                    </Tippy>
                                                )}

                                                {showDelete && (
                                                    <button
                                                        type="button"
                                                        onClick={async () => {
                                                            if (confirm(t('web_admin_confirm_delete_franchise_user'))) {
                                                                await deleteUser(item)
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </InfiniteScroll>
            ) : (
                <p className="text-sm">
                    <Trans
                        i18nKey={search ? 'web_franchise_users_no_items_search' : 'web_franchise_users_no_items'}
                        components={[<strong key={0} />]}
                        values={{ search }}
                    />
                </p>
            )}
            {gotoTopButton && (
                <div
                    className="fixed bottom-5 right-5 cursor-pointer rounded bg-chathams-blue p-2 text-white"
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                        })
                    }
                >
                    <FontAwesomeIcon icon={faChevronsUp} fixedWidth />
                </div>
            )}
            <Modal
                overflowHidden={false}
                disableSize
                className="w-3/4 xl:w-1/2"
                isOpen={readOnlyModal}
                confirmDisabled={readOnlyProcessing}
                cancelDisabled={readOnlyProcessing}
                onRequestClose={() => {
                    showReadOnlyModal(false)
                    setReadOnlyUser(undefined)
                }}
                onConfirm={async () => {
                    await flagReadOnly(readOnlyUser)
                }}
                cancelLabel={t('web_flag_read_only_user_cancel')}
                onCancel={() => {
                    showReadOnlyModal(false)
                    setReadOnlyUser(undefined)
                }}
                title={t('web_flag_read_only_user_title')}
                okLabel={t('web_flag_read_only_user_confirm')}
                bottomAlignment="center"
            >
                {readOnlyProcessing ? (
                    <div className="flex flex-col items-center gap-4">
                        <div className="text-primary relative z-10">{t('web_read_only_processing')}</div>
                        <SmallLoader className="relative z-0" />
                    </div>
                ) : (
                    readOnlyUser && (
                        <div className="flex flex-col gap-2 py-8">
                            <p>
                                {t('web_flag_read_only_user_summary1', {
                                    user: `${readOnlyUser.firstname} ${readOnlyUser.lastname}`,
                                })}
                            </p>
                            <p>{t('web_flag_read_only_user_summary2')}</p>
                            <p className="flex flex-row items-center gap-2">
                                <span>{t('web_flag_read_only_user_access_duration1')}</span>
                                <Select
                                    styles={selectStyles}
                                    options={readOnlyDurations}
                                    onChange={value => {
                                        if (value) {
                                            setReadOnlyAccessDuration(value.value as ReadOnlyAccessDuration)
                                        }
                                    }}
                                    defaultValue={readOnlyDurations.find(
                                        ({ value }) => value === readOnlyDefaultAccessDuration
                                    )}
                                />
                                <span>{t('web_flag_read_only_user_access_duration2')}</span>
                            </p>
                        </div>
                    )
                )}
            </Modal>
        </Admin>
    )
}

export default Users
