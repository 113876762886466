const Config = {
    env: process.env.REACT_APP_ENV,
    app: {
        APIKEY: process.env.REACT_APP_API_KEY,
        APIURL: process.env.REACT_APP_API_HOST,
        WEBURL: process.env.REACT_APP_WEB_HOST,
        WSURL: process.env.REACT_APP_WS_HOST,
        WS_DEBUG: process.env.REACT_APP_WS_DEBUG === 'true',
        DEFAULT_LOCALE: 'fr',
        TIMEOUT_DURATION: Number.parseInt(process.env.REACT_APP_TIMEOUT_DURATION ?? '0'),
        PING_INTERVAL: Number.parseInt(process.env.REACT_APP_PING_INTERVAL ?? '2'),
        version: {
            BUILD_DATE: Number.parseInt(process.env.REACT_APP_BUILD_DATE ?? ')'),
            RELEASE: '20.8.3.0',
            NUMBER: 0,
        },
        VOUSFINANCER_V3_ENABLED: process.env.REACT_APP_VOUSFINANCER_V3_ENABLED === 'true',
        FEATURE_FLAG_E_SIGN_DOCUMENT: process.env.REACT_APP_FEATURE_FLAG_E_SIGN_DOCUMENT === 'true',
    },
    i18n: {
        EXPIRATION_TIME: Number.parseInt(process.env.REACT_APP_I18N_EXPIRATION_TIME ?? '86400000'),
    },
    sso: {
        apple: {
            CLIENT_ID: process.env.REACT_APP_APPLE_SSO_CLIENT_ID,
            REDIRECT_URI: process.env.REACT_APP_APPLE_SSO_REDIRECT_URI,
        },
        google: {
            CLIENT_ID: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID,
        },
    },
    http: {
        CODE_204: 'web_error_no_content',
        CODE_400: 'web_error_bad_request',
        CODE_401: 'web_error_not_logged',
        CODE_403: 'web_error_forbidden',
        CODE_404: 'web_error_not_found',
        CODE_405: 'web_error_method_not_allowed',
        CODE_412: 'web_error_precondition_failed',
        CODE_422: 'web_error_unprocessable_entity',
        CODE_500: 'web_error_internal_server_error',
        CODE_503: 'web_error_service_unavailable',
    },
}

export default Config
