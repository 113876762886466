export const E_SIGN_DOCUMENT_STATUS = {
    EMPTY: '',
    STARTED: 'started',
    PAUSE: 'pause',
    EXPIRED: 'expired',
    DRAFT: 'draft',
    COMPLETED: 'completed',
    CLOSED: 'closed',
    CANCELLED: 'cancelled',
} as const
