import { zodResolver } from '@hookform/resolvers/zod'
import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import Label from 'components/admin/label'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg'
import type { DetachedSpaceOrDirectoryOrFile } from '../../stores/files'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'

interface ESignDocumentModalProps {
    isVisible: boolean
    onClose: () => void
    selection: DetachedSpaceOrDirectoryOrFile[]
    onConfirm: (DetachedSpaceOrDirectoryOrFile) => void
}

interface Signer {
    email: string
    firstname: string
    lastname: string
    phonenumber: string
}

export interface ESignRequestInfo {
    transactionName: string
    mailSubject: string
    mailBody: string
    signers: Signer[]
}

const phoneRegex = new RegExp(/^[\+]{1}[-\s\.]?[1-9]{1,3}[0-9\-\s\.]{5,15}$/)

const schema = z.object({
    email: z.string().email(),
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    phonenumber: z.string().regex(phoneRegex).optional().or(z.literal('')),
})
type Schema = z.infer<typeof schema>

const ESignDocumentModal = observer(({ isVisible, onClose, selection, onConfirm }: ESignDocumentModalProps) => {
    const { t } = useTranslation()
    const { files, user } = useMst()
    const [signers, setSigners] = useState<Signer[]>([])
    const [transactionName, setTransactionName] = useState<string>(
        t('web_modal_e_sign_document_placeholder_transaction_name')
    )
    const [mailSubject, setMailSubject] = useState<string>(t('web_modal_e_sign_document_placeholder_mail'))
    const [mailBody, setMailBody] = useState<string>(t('web_modal_e_sign_document_placeholder_mail'))

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })
    const onSubmit = (data: Schema) => {
        if (signers.some(signer => signer.email === data.email)) {
            toast('error', t('web_modal_add_signers'), undefined, t('api_add_trusted_person_error'))
            reset()
            return
        }
        const signer = {
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            phonenumber: data.phonenumber,
        }
        reset()
        setSigners(signers => [...signers, signer])
    }
    const sendSign = async () => {
        if (signers.length <= 0) {
            toast('error', t('web_modal_add_signers'), undefined, t('web_modal_sign_error_no_signers'))
            return
        }
        const [selectedFile] = selection
        await files.startESignDocument(selectedFile.uuid, {
            transactionName,
            mailSubject,
            mailBody,
            signers,
        })
        setSigners([])
        reset()
        onConfirm(selectedFile)
    }

    const addMyself = () => {
        if (signers.some(signer => signer.email === user.email)) {
            toast('error', t('web_modal_add_signers'), undefined, t('web_modal_sign_error_no_signers'))
            reset()
            return
        }
        const signer = {
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            phonenumber: user.phone,
        }
        setSigners(signers => [...signers, signer])
    }

    if (!isVisible) {
        return null
    }

    return (
        <>
            <Modal
                size="11/12"
                isOpen={isVisible}
                onRequestClose={() => close()}
                title={t('web_modal_e_sign_document_title')}
                onCancel={() => {
                    setSigners([])
                    reset()
                    onClose()
                }}
                bottomChildren={
                    <button className="btn" type="button" onClick={async () => sendSign()}>
                        {t('web_modal_e_sign_document_send')}
                    </button>
                }
            >
                <div className="grid w-full grid-cols-2 gap-4 pr-5 divide-x">
                    <div className="grid w-full gap-4">
                        <Label
                            name="signTransactionName"
                            label={'web_modal_e_sign_document_transaction_name'}
                            className="my-5 flex flex-wrap items-center"
                            required={false}
                        >
                            <input
                                type="text"
                                className="w-full"
                                autoComplete="off"
                                onChange={event => setTransactionName(event.target.value)}
                                placeholder={t('web_modal_e_sign_document_placeholder_transaction_name')}
                            />
                        </Label>
                        <Label
                            name="signTransactionName"
                            label={'web_modal_e_sign_document_email_title'}
                            className="my-5 flex flex-wrap items-center"
                            required={false}
                        >
                            <input
                                type="text"
                                className="w-full"
                                autoComplete="off"
                                onChange={event => setMailSubject(event.target.value)}
                                placeholder={t('web_modal_e_sign_document_placeholder_mail')}
                            />
                        </Label>
                        <Label
                            name="signTransactionName"
                            label={'web_modal_e_sign_document_email_body'}
                            className="my-5 flex flex-wrap items-center"
                            required={false}
                        >
                            <input
                                type="text"
                                className="w-full"
                                autoComplete="off"
                                onChange={event => setMailBody(event.target.value)}
                                placeholder={t('web_modal_e_sign_document_placeholder_mail')}
                            />
                        </Label>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid w-full ml-4 grid-cols-2 gap-4">
                            <Label
                                name="add_trusted_email"
                                label={'web_share_add_trusted_email'}
                                className="my-5 col-span-2 flex w-full flex-wrap items-center"
                                error={errors.email?.message && `${errors.email?.message}`}
                                required
                            >
                                <input
                                    {...register('email')}
                                    type="text"
                                    className="w-full"
                                    autoComplete="off"
                                    placeholder={t('web_share_add_trusted_email')}
                                />
                            </Label>
                            <Label
                                name="add_trusted_lastname"
                                label={'web_share_add_trusted_lastname'}
                                className="my-5 flex w-full flex-wrap items-center"
                                error={errors.lastname?.message && `${errors.lastname?.message}`}
                                required
                            >
                                <input
                                    {...register('lastname')}
                                    type="text"
                                    className="w-full"
                                    autoComplete="off"
                                    placeholder={t('web_share_add_trusted_lastname')}
                                />
                            </Label>
                            <Label
                                name="add_trusted_firstname"
                                label={'web_share_add_trusted_firstname'}
                                className="my-5 flex w-full flex-wrap items-center"
                                error={errors.firstname?.message && `${errors.firstname?.message}`}
                                required
                            >
                                <input
                                    {...register('firstname')}
                                    type="text"
                                    className="w-full"
                                    autoComplete="off"
                                    placeholder={t('web_share_add_trusted_firstname')}
                                />
                            </Label>

                            <Label
                                name="web_share_add_trusted_mobile"
                                error={errors.phonenumber?.message && t('web_modal_e_sign_document_error_phone')}
                                label={'web_share_add_trusted_mobile'}
                                className="my-5 col-span-2 flex w-full flex-wrap items-center"
                                required={false}
                            >
                                <input
                                    {...register('phonenumber')}
                                    type="text"
                                    className="w-full"
                                    autoComplete="off"
                                    placeholder={t('web_modal_e_sign_document_placeholder_phone')}
                                />
                            </Label>
                        </div>
                        <div className="flex items-center justify-center space-x-4">
                            <button type="submit" className="btn mt-10 px-8">
                                {t('web_modal_add_signers')}
                            </button>
                            {signers.some(signer => signer.email === user.email) ? null : (
                                <button
                                    type="button"
                                    className="btn mt-10 px-8"
                                    onClick={() => {
                                        addMyself()
                                    }}
                                >
                                    {t('web_modal_add_sign_me')}
                                </button>
                            )}
                        </div>
                    </form>
                </div>
                <div className="items-center justify-between ml-8 mr-8 mb-8 mt-4 flex flex-wrap">
                    {signers.map(signer => (
                        <div key={signer.email} className="flex flex-col mb-3 px-6 py-4 shadow-md">
                            <div className="relative self-end">
                                <IconTrash
                                    className="cursor-pointer fill-current text-regent-gray"
                                    title={t('web_link_trash')}
                                    onClick={() =>
                                        setSigners(signers.filter(signerFilter => signer.email !== signerFilter.email))
                                    }
                                />
                            </div>
                            <div className="mt-4 flex flex-row items-center">
                                <div className="relative h-12 w-12 rounded-full bg-regent-gray">
                                    <div className="absolute inset-0 flex h-12 w-12 items-center justify-center overflow-hidden rounded-full text-white">
                                        <IconPlaceholder className="h-8 w-8 fill-current" />
                                    </div>
                                </div>
                                <div
                                    className="-mt-1 ml-4 flex flex-col text-thunder"
                                    style={{
                                        width: 'calc(100% - 2.5rem - 0.25rem - 2rem)',
                                    }}
                                >
                                    <p className="font-bold">
                                        {signer.firstname} {signer.lastname}
                                    </p>
                                    <p className="text-xs font-semibold">{signer.email}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    )
})

export default ESignDocumentModal
