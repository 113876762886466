import type { FileWithPath } from 'components/dataroom/upload/upload'
import { z } from 'zod'
import {
    FILE_TRANSFER_MESSAGE_MAX_LENGTH,
    FILE_TRANSFER_OBJECT_MAX_LENGTH,
    FILE_TRANSFER_TITLE_MAX_LENGTH,
} from './constants'

export type FileWithPathAndExtension = FileWithPath & { extension: string; isSizeValid: boolean }
export const emailSchema = z.string().email().toLowerCase().or(z.literal(''))
export const transferFormSchema = z
    .object({
        senderEmail: emailSchema,
        shareType: z.enum(['email', 'link']),
        recipients: z
            .string()
            .optional()
            .transform(value => value.split(';').map(email => email.trim()))
            .refine(emails => {
                if (emails.length === 0) {
                    return true
                }

                for (const email of emails) {
                    const result = emailSchema.safeParse(email)

                    if (!result.success) {
                        return false
                    }
                }

                return true
            }),
        title: z.string().max(FILE_TRANSFER_TITLE_MAX_LENGTH).optional(),
        object: z.string().max(FILE_TRANSFER_OBJECT_MAX_LENGTH).optional(),
        message: z.string().max(FILE_TRANSFER_MESSAGE_MAX_LENGTH).optional(),
        isSecure: z.boolean().default(false),
        downloadNotify: z.boolean().default(false),
    })
    .superRefine((values, ctx) => {
        // TODO validate data depending on shareType

        return true
    })

export type TransferFormData = z.infer<typeof transferFormSchema>
export type ShareType = Pick<TransferFormData, 'shareType'>['shareType']

export interface ApiInitTransferResponse {
    cuid: string
}

export interface ApiUploadFileResponse {
    url: string
}

export interface ApiDoneTransferResponse {
    url: string
    shareType: ShareType
    secure: boolean
}
export interface ApiTransferInfosResponse {
    cuid: string
    daysAvailable: number
    expired: boolean
    shareType: ShareType
    isSecure: boolean
}

export interface ApiDownloadResponse {
    url: string
    name: string
}

export const inputClassName =
    'w-full font-nunito border border-0 border-b border-b-[#B5C0CC] placeholder:text-regent-grey text-outer-space'

export const buttonClassName =
    'bg-atomic-tangerine text-white font-bold text-lg px-10 py-1 rounded-md disabled:bg-regent-gray'

export const emailFormSchema = z.object({
    email: emailSchema,
})
export type EmailFormData = z.infer<typeof emailFormSchema>

export const codeFormSchema = z.object({
    accessCode: z.string().min(6).max(6),
})
export type CodeFormData = z.infer<typeof codeFormSchema>

export type UploadState = 'pending_user' | 'uploading' | 'finalizing' | 'ready'
