import { faFolderOpen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import weTreasyBannerImage from 'assets/file-transfer/banner-we-treasy.webp'
import { ReactComponent as SpaceIcon } from 'assets/icons/icon-folder.svg'
import logo from 'assets/large-logo.svg'
import image from 'assets/telechargement-branding.jpg'
import ViaTreasy from 'assets/via-treasy'
import { clsx } from 'clsx'
import { fileColors } from 'components/dataroom/upload/file-colors'
import { getFileSize } from 'components/dataroom/upload/file-size'
import { getExtension } from 'components/dataroom/upload/mime-types'
import Config from 'core/config'
import { getWeTreasyUrl } from 'core/utils/get-we-treasy-url'
import { DateTime } from 'luxon'
import { type CSSProperties, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import type { GuestDirectory, GuestFile, GuestSharing, GuestSpace } from 'stores/files/file'
import { useMst } from 'stores/store'

const File = ({ file }: { file: GuestFile }) => {
    const { t } = useTranslation()

    const createdAt = DateTime.fromMillis(file.dateAdded)
    const extension = getExtension(file.name)

    return (
        <div className={clsx('flex flex-col justify-center gap-1 py-4')}>
            <span>{file.name}</span>
            <div className="flex items-center gap-2 text-sm text-regent-gray">
                <span className="hidden xl:block">{createdAt.toLocaleString(DateTime.DATE_SHORT)}</span>
                <span className="xl:hidden">{createdAt.year}</span>
                <span>-</span>
                <span className="hidden xl:block">
                    {extension && Object.keys(fileColors).includes(extension)
                        ? t('web_space_file_type', { type: extension })
                        : t('web_space_file')}
                </span>
                <span className="xl:hidden">{extension}</span>
                <span>-</span>
                <span>{getFileSize(t, file.size)}</span>
            </div>
        </div>
    )
}

const Directory = ({ directory }: { directory: GuestDirectory }) => {
    const { t } = useTranslation()

    return (
        <div className={clsx('flex flex-col justify-center gap-1 py-4')}>
            <span>{directory.name}</span>
            <div className="flex items-center gap-2 text-sm text-regent-gray">
                <FontAwesomeIcon icon={faFolderOpen} className="fill-current" />
                <span>{t('web_spaceroom_directory')}</span>
            </div>
        </div>
    )
}

const Space = ({ space }: { space: GuestSpace }) => {
    const { t } = useTranslation()

    return (
        <div className={clsx('flex flex-col justify-center gap-1 py-4')}>
            <span>{space.name}</span>
            <div className="flex items-center gap-2 text-sm text-regent-gray">
                <SpaceIcon className="h-4 w-4 flex-none fill-current" />
                <span>{t('web_spaceroom_space')}</span>
            </div>
        </div>
    )
}

const makeFilename = (archive: string | undefined) => {
    if (!archive) {
        return ''
    }

    const parts = archive.split('/')

    return `${parts.at(-1).replace(/\.zip$/, '')}.zip`
}

const weTreasyUrl = getWeTreasyUrl()

const Share = () => {
    const { files, user } = useMst()
    const { shareId } = useParams()
    const { t } = useTranslation()

    const [share, setShare] = useState<GuestSharing>()
    const [shareUrl, setShareUrl] = useState<URL>()
    const [loaded, setLoaded] = useState<boolean>(false)

    const load = async (shareId: string) => {
        const share = await files.getShare(shareId)
        setShare(share)

        const url = new URL(`/v1/web/s/${share.uuid}`, Config.app.APIURL)
        if (user?.uuid) {
            url.searchParams.append('user', user.uuid)
        }

        setShareUrl(url)
        setLoaded(true)
    }
    useEffect(() => {
        if (shareId) {
            load(shareId)
        }
    }, [shareId])

    return (
        <div className="relative grid h-full min-h-screen max-w-full bg-athens-gray lg:grid-cols-2">
            <div className="relative">
                <div
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                    style={{
                        backgroundImage: `url(${share?.partner?.brandImage ? share.partner.brandImage : image})`,
                    }}
                />
                <div className="relative z-10 flex w-full justify-start">
                    {share?.partner?.brandLogo && share.partner.brandLogo !== '' ? (
                        <div className={clsx('flex flex-col flex-wrap items-center justify-between p-1')}>
                            <div className="flex grow justify-start">
                                <img
                                    src={share.partner.brandLogo}
                                    className="m-auto max-h-20"
                                    alt={share.partner.name}
                                />
                            </div>
                            <div
                                className={clsx(
                                    'flex grow justify-end',
                                    (!share || !share.partner || !share.partner.viaColor) && 'text-thunder'
                                )}
                                style={{
                                    color: share?.partner?.viaColor ? share.partner.viaColor : undefined,
                                }}
                            >
                                <ViaTreasy />
                            </div>
                        </div>
                    ) : (
                        <img src={logo} className="p-4" alt="Treasy" />
                    )}
                </div>
            </div>

            <div className="p-8 lg:p-12">
                {share ? (
                    <div className="flex h-full flex-col justify-around">
                        <div className="flex flex-col gap-4">
                            <h1 className="text-2xl font-bold">{t('web_share_shared_by', { name: share.sharedBy })}</h1>
                            <h2 className="flex gap-2 border-b border-heather pb-8 text-lg font-bold">
                                {share.fileCount > 0 && (
                                    <>
                                        <span>{t('web_share_file_count', { count: share.fileCount })}</span>
                                        <span>-</span>
                                    </>
                                )}
                                {share.size > 0 && (
                                    <>
                                        <span>{getFileSize(t, share.size)}</span>
                                        <span>-</span>
                                    </>
                                )}
                                <span>
                                    {t('web_share_file_expires', {
                                        date: DateTime.fromMillis(share.validTo).toLocaleString(DateTime.DATE_HUGE),
                                    })}
                                </span>
                            </h2>

                            <div className="flex flex-col justify-between divide-y divide-mercury">
                                <h3 className="mb-4 text-xl font-bold">{makeFilename(share.archiveName)}</h3>
                                {share.files.map(file => {
                                    if (file.file && file.file.uuid !== '') {
                                        return <File key={file.uuid} file={file.file} />
                                    }
                                    if (file.directory && file.directory.uuid !== '') {
                                        return <Directory key={file.uuid} directory={file.directory} />
                                    }
                                    if (file.space && file.space.uuid !== '') {
                                        return <Space key={file.uuid} space={file.space} />
                                    }

                                    return null
                                })}
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <div className="flex w-full items-center justify-center">
                                {share.archive && share.archive.startsWith('https://') && shareUrl ? (
                                    <a
                                        href={shareUrl.toString()}
                                        download
                                        className="btn px-8"
                                        style={
                                            {
                                                backgroundColor: share.partner ? share.partner.color : undefined,
                                                //'atomic-tangerine': '0 4px 6px -1px rgba(253, 168, 106, 0.5), 0 2px 4px 2px rgba(253, 168, 106, 0.06)',
                                                '--tw-shadow-color': share.partner ? share.partner.color : undefined,
                                            } as CSSProperties
                                        }
                                    >
                                        {t('web_share_download')}
                                    </a>
                                ) : (
                                    <span className="text-sm italic">{t('web_share_download_not_available')}</span>
                                )}
                            </div>

                            <p className="prose mt-8 text-sm">
                                <Trans i18nKey="web_share_discover_treasy">
                                    Pas encore de compte Treasy ?
                                    <a href="https://www.treasy.fr" target="_blank" rel="noreferrer">
                                        Créez votre compte maintenant
                                    </a>
                                    et profitez d’un coffre fort numérique pour votre gestion courante.
                                </Trans>
                            </p>
                        </div>

                        <div className="mt-8 lg:mt-16 relative border-t pt-4 lg:pt-8">
                            <h2 className="prose text-sm lg:text-lg font-semibold mb-2">
                                {t('web_share_we_treasy_title')}
                            </h2>

                            <a href={weTreasyUrl} target="_blank" rel="noreferrer">
                                <img src={weTreasyBannerImage} alt={t('web_share_we_treasy_image_alt')} />
                            </a>

                            <div className="mt-2 text-center">
                                <a href={weTreasyUrl} target="_blank" className="btn inline-block" rel="noreferrer">
                                    {t('web_share_we_treasy_cta')}
                                </a>
                            </div>
                        </div>
                    </div>
                ) : loaded ? (
                    <div className="flex flex-col gap-4">
                        <h1 className="text-2xl font-bold">{t('web_share_no_file_title')}</h1>
                        <p>{t('web_share_no_file')}</p>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Share
