import { observer } from 'mobx-react-lite'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { ShareGroup } from 'stores/shareGroup/share-group'
import type { TrustedUser } from 'stores/trustedUser/trusted-user'
import { useMst } from '../../stores/store'
import FakeTrustedUser from '../dataroom/fake-trusted-user'
import AddTrustedPerson from '../dataroom/sharing/add-trusted-person'
import FindPartner from '../dataroom/sharing/find-partner'
import UserCard from '../dataroom/user-card'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import SlidingPanelGeneral from '../shared/sliding-panel-general'
import SmallLoader from '../shared/small-loader'
import ProfilePage from './profile-page'

const Friends: FC = observer(() => {
    const { t } = useTranslation()
    const { userTypeStore, trustedUserStore /*, shareGroup*/ } = useMst()

    const [loading, setLoading] = useState<boolean>(true)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groups, setGroups] = useState<ShareGroup[] | []>([])
    const [showAddTrustedPersonModal, setShowAddTrustedPersonModal] = useState<boolean>(false)
    const [showFindPartnerModal, setShowFindPartnerModal] = useState<boolean>(false)
    const [userToDelete, setUserToDelete] = useState<TrustedUser>()

    const refresh = async () => {
        await userTypeStore.load()
        await trustedUserStore.load()
        // const sharedGroups = await shareGroup.load()
        setLoading(false)
        // setGroups(sharedGroups)
        setShowAddTrustedPersonModal(false)
        setShowFindPartnerModal(false)
    }

    const askConfirmDelete = (trustedUser: TrustedUser) => {
        setUserToDelete(trustedUser)
    }

    const confirmDelete = async () => {
        await trustedUserStore.remove(userToDelete)
        setUserToDelete(undefined)
        await refresh()
    }

    useEffect(() => {
        if (loading) {
            refresh()
        }
    }, [loading])

    return (
        <ProfilePage url="/me/friends">
            <Panel className="mb-8 mt-4">
                {/* <div>
                <span className="text-atomic-tangerine float-right cursor-pointer font-bold"
                    onClick={() => setShowCreateTrustedUserModal(true)}>{t('api_add_new_trusted_user')}</span>
            </div> */}
                <div>
                    <span
                        className="float-right cursor-pointer font-bold text-atomic-tangerine"
                        onClick={() => setShowAddTrustedPersonModal(true)}
                    >
                        {t('api_add_trusted_person')}
                    </span>
                </div>
                <div>
                    <span
                        className="float-right cursor-pointer pr-5 font-bold text-atomic-tangerine"
                        onClick={() => setShowFindPartnerModal(true)}
                    >
                        {t('api_find_partner')}
                    </span>
                </div>
                <div>
                    {loading ? (
                        <SmallLoader />
                    ) : (
                        <>
                            {trustedUserStore.trustedUsers.length === 0 &&
                                trustedUserStore.franchises.length === 0 &&
                                trustedUserStore.brands.length === 0 && (
                                    <FakeTrustedUser onClick={() => setShowAddTrustedPersonModal(true)} />
                                )}

                            {trustedUserStore.trustedUsers.filter(trustedUser => trustedUser.userType?.uuid === '')
                                .length > 0 && (
                                <SlidingPanelGeneral
                                    title={t('web_share_trusted_people_users_type')}
                                    isOpen
                                    isAvailable
                                    search={null}
                                    innerContent={
                                        <>
                                            {trustedUserStore.trustedUsers
                                                .filter(trustedUser => trustedUser.userType?.uuid === '')
                                                .map(trustedUser => (
                                                    <UserCard
                                                        key={trustedUser.uuid}
                                                        user={trustedUser}
                                                        onClick={() => {}}
                                                        onDelete={() => askConfirmDelete(trustedUser)}
                                                    />
                                                ))}
                                        </>
                                    }
                                />
                            )}

                            {trustedUserStore.brands.map((brand, i) => (
                                <SlidingPanelGeneral
                                    isOpen
                                    key={brand}
                                    isAvailable
                                    title={t('web_share_brand_trusted_people', { brand })}
                                    innerContent={
                                        <>
                                            {trustedUserStore.trustedUsersPerBrand[i].map(trustedUser => (
                                                <UserCard
                                                    user={trustedUser}
                                                    onClick={() => {}}
                                                    key={trustedUser.uuid}
                                                    onDelete={() => askConfirmDelete(trustedUser)}
                                                />
                                            ))}
                                        </>
                                    }
                                />
                            ))}

                            {trustedUserStore.franchises.map((franchise, i) => (
                                <SlidingPanelGeneral
                                    isOpen
                                    key={franchise}
                                    isAvailable
                                    title={t('web_share_franchise_trusted_people', { franchise })}
                                    innerContent={
                                        <>
                                            {trustedUserStore.trustedUsersPerFranchise[i].map(trustedUser => (
                                                <UserCard
                                                    user={trustedUser}
                                                    onClick={() => {}}
                                                    key={trustedUser.uuid}
                                                    onDelete={() => askConfirmDelete(trustedUser)}
                                                />
                                            ))}
                                        </>
                                    }
                                />
                            ))}

                            {trustedUserStore.pendingInvitations.length > 0 && (
                                <SlidingPanelGeneral
                                    title={t('web_collaboration_pending_invitations')}
                                    isOpen
                                    isAvailable
                                    search={null}
                                    innerContent={
                                        <>
                                            {trustedUserStore.pendingInvitations
                                                .filter(trustedUser => trustedUser.userType?.uuid === '')
                                                .map(trustedUser => (
                                                    <UserCard
                                                        key={trustedUser.uuid}
                                                        user={trustedUser}
                                                        onClick={() => {}}
                                                        onDelete={() => askConfirmDelete(trustedUser)}
                                                    />
                                                ))}
                                        </>
                                    }
                                />
                            )}

                            {groups.length > 0 ? (
                                <SlidingPanelGeneral
                                    title={'Groups'}
                                    isOpen
                                    isAvailable
                                    search={null}
                                    innerContent={
                                        <>
                                            {groups.map(shareGroup => (
                                                <>
                                                    <div className="w-full flex-none p-2 md:w-1/2 lg:w-1/3 xl:w-1/4">
                                                        <div className="flex flex-col px-6 py-4 shadow-md">
                                                            <div className="mt-4 flex flex-row items-center">
                                                                <div
                                                                    className="-mt-1 ml-4 flex flex-col text-thunder"
                                                                    style={{
                                                                        width: 'calc(100% - 2.5rem - 0.25rem - 2rem)',
                                                                    }}
                                                                >
                                                                    <p className="mb-8 font-bold">
                                                                        {shareGroup.groupName}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    }
                                />
                            ) : (
                                <></>
                            )}

                            {userTypeStore.userTypes.map(userType => {
                                const filtered = trustedUserStore.trustedUsers.filter(
                                    trustedUser => trustedUser.userType?.uuid === userType.uuid
                                )
                                if (filtered.length === 0) {
                                    return null
                                }

                                return (
                                    <SlidingPanelGeneral
                                        key={userType.uuid}
                                        title={userType.name}
                                        isOpen
                                        isAvailable
                                        search={null}
                                        innerContent={
                                            <>
                                                {filtered.map(trustedUser => (
                                                    <UserCard
                                                        key={trustedUser.uuid}
                                                        user={trustedUser}
                                                        userType={userType}
                                                        onClick={() => {}}
                                                        onDelete={() => askConfirmDelete(trustedUser)}
                                                    />
                                                ))}
                                            </>
                                        }
                                    />
                                )
                            })}
                        </>
                    )}

                    <Modal
                        size="1/2"
                        isOpen={showAddTrustedPersonModal}
                        onRequestClose={() => setShowAddTrustedPersonModal(false)}
                    >
                        <AddTrustedPerson
                            onAdd={() => refresh()}
                            onCancel={() => setShowAddTrustedPersonModal(false)}
                        />
                    </Modal>

                    <Modal
                        size="1/2"
                        isOpen={showFindPartnerModal}
                        onRequestClose={() => setShowFindPartnerModal(false)}
                    >
                        <FindPartner onAdd={() => refresh()} onCancel={() => setShowFindPartnerModal(false)} />
                    </Modal>

                    <Modal
                        isOpen={userToDelete !== undefined}
                        size="1/4"
                        onCancel={() => setUserToDelete(undefined)}
                        onRequestClose={() => setUserToDelete(undefined)}
                        cancelLabel={t('web_friend_confirm_delete_trusted_user_cancel')}
                        okLabel={t('web_friend_confirm_delete_trusted_user_confirm')}
                        onConfirm={() => confirmDelete()}
                    >
                        <h4 className="my-4 text-center text-xl">
                            {t(
                                userToDelete?.collaboration
                                    ? 'web_collaboration_space_invitation_delete_title'
                                    : 'web_friend_confirm_delete_trusted_user_title'
                            )}
                        </h4>
                        <p>
                            {t(
                                userToDelete?.collaboration
                                    ? 'web_collaboration_space_invitation_delete_desc'
                                    : 'web_friend_confirm_delete_trusted_user_desc',
                                {
                                    name: userToDelete?.trustedUser?.fullname ?? userToDelete?.trustedUser?.email ?? '',
                                    email: userToDelete?.trustedUser?.email ?? '',
                                    space: userToDelete?.collaboration?.space.name ?? '',
                                }
                            )}
                        </p>
                    </Modal>
                </div>
            </Panel>
        </ProfilePage>
    )
})

export default Friends
