import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import type { BackupFile } from 'stores/files'
import type { Partner } from 'stores/partners'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import Backup from './backup'
import ProfilePage from './profile-page'

const Account = observer(() => {
    const { user, paymentManager, files } = useMst()
    const { t } = useTranslation()

    const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState<boolean>(false)
    const [backup, setBackup] = useState<BackupFile>()
    const [partner] = useState<Partner>(user.partner)

    const load = useCallback(async () => {
        await user.refreshUser()
        await paymentManager.getStatus()
        await user.loadPartner()
    }, [paymentManager])

    useEffect(() => {
        load()
    }, [paymentManager])

    const confirmDelete = async () => {
        setShowConfirmDeleteAlert(false)
        await user.deleteMe()
    }

    const checkForBackup = async () => {
        await files.checkForBackup()
        if (files.backup && files.backup.status === 'none') {
            setBackup(null)

            return
        }
        setBackup(files.backup)
    }

    const getBackup = async () => {
        await files.postBackup()
        await checkForBackup()
        toast('success', 'web_me_backup_waiting')
    }

    useEffect(() => {
        checkForBackup()
    }, [files])

    return (
        <ProfilePage url="/me/account">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col md:w-2/3">
                    {partner.contactUrl ? (
                        <>
                            <div className="mb-4 mt-8">
                                <h3 className="mb-2 text-lg">{t('web_admin_partner_contact')}</h3>
                                <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                    <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                        <Trans i18nKey="web_me_profile_partner_contact_url">
                                            Treasy se chargera d’archiver les données présentent sur votre compte
                                            Treasy. Les liens de partage, transmissions et télécollectes seront rompus.
                                            Si vous souhaitez récupérer vos données, contactez
                                            <a href="mailto:assistance@treasy.fr?subject=Suppression des données">
                                                notre service client via notre formulaire de contact
                                            </a>
                                            .
                                        </Trans>
                                    </span>
                                    <div className="flex w-full items-center space-x-4 md:w-1/2">
                                        <a
                                            href={partner.contactUrl}
                                            target="_blank"
                                            className="btn white w-full "
                                            rel="noreferrer"
                                        >
                                            {t('web_admin_partner_contact_url')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <div className="mb-4 mt-8">
                        <h3 className="mb-2 text-lg">{t('web_me_profile_delete_account')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                <Trans i18nKey="web_me_profile_delete_account_desc">
                                    Treasy se chargera d’archiver les données présentent sur votre compte Treasy. Les
                                    liens de partage, transmissions et télécollectes seront rompus. Si vous souhaitez
                                    récupérer vos données, contactez{' '}
                                    <a href="mailto:assistance@treasy.fr?subject=Suppression des données">
                                        notre service client via notre formulaire de contact
                                    </a>
                                    .
                                </Trans>
                            </span>
                            <button
                                type="button"
                                className="btn white w-full md:w-1/2"
                                onClick={() => setShowConfirmDeleteAlert(true)}
                            >
                                {t('web_me_profile_delete_account_confirm')}
                            </button>
                        </div>
                    </div>

                    <div className="mb-4 mt-8">
                        <h3 className="mb-2 text-lg">{t('web_download_files_title')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                <Trans i18nKey="web_download_files_desc">web_download_files_desc</Trans>
                            </span>

                            <div className="w-full md:w-1/2">
                                {backup ? (
                                    <Backup backup={backup} />
                                ) : (
                                    <>
                                        {paymentManager.payment.active ? (
                                            <button className="btn white w-full" disabled type="button">
                                                {t('web_me_profile_download_treasy')}
                                            </button>
                                        ) : (
                                            <button className="btn white" onClick={() => getBackup()} type="button">
                                                {t('web_download_files')}
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>

            <Modal
                isOpen={showConfirmDeleteAlert}
                bottomAlignment="center"
                onRequestClose={() => setShowConfirmDeleteAlert(false)}
                cancelLabel={t('web_confirm_delete_cancel')}
                okLabel={t('web_confirm_delete_ok')}
                onConfirm={() => confirmDelete()}
                onCancel={() => setShowConfirmDeleteAlert(false)}
            >
                <h2 className="mb-2 mt-4 text-xl font-bold">{t('web_confirm_delete_title')}</h2>
                <p className="my-6 text-center text-sm text-pale-sky">{t('web_confirm_delete_desc')}</p>
            </Modal>
        </ProfilePage>
    )
})

export default Account
