import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { CropAs } from 'core/images/canvas-utils'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import { z } from 'zod'
import ColorPicker from '../color-picker'
import { type FormProps, optionValueSchema } from '../forms'
import Label from '../label'
import PictureUpload from '../picture-upload'
import '../zod'

const schema = z.object({
    name: z.string().min(1),
    url: z.string().url().optional(),
    logo: z.string(),
    icon: z.string(),
    color: z.string(),
    viaColor: z.string(),
    brandImage: z.string().optional(),
    brandLogo: z.string(),
    configFile: optionValueSchema,
    role_admin_name: z.string(),
    role_admin_controller: z.string(),
    role_admin_franchisee: z.string(),
    role_admin_agent: z.string(),
    show_user_profile: z.boolean().default(false),
    show_models: z.boolean().default(false),
    secure_collaboration: z.boolean().default(false),
    show_telecollecte: z.boolean().default(false),
    admin_can_add_users: z.boolean().default(false),
    show_crisp_button: z.boolean().default(false),
    disconnect_timeout: z.boolean().default(false),
    franchise_label: z.string(),
    client_label: z.string(),
    userPricing: optionValueSchema,
    partnerPricing: optionValueSchema,

    hrEnabled: z.boolean().default(false),
    hrCutFile: z.boolean().default(false),
    hrFilePattern: z.string(),
    ocrJobTag: z.string(),
})
export type BrandFormValues = z.infer<typeof schema>

export const configFiles = [
    { value: 'empty', label: 'empty' },
    { value: 'caplconseil', label: 'caplconseil' },
    { value: 'agami', label: 'agami' },
    { value: 'broker', label: 'broker' },
    { value: 'broker_no_client_create', label: 'broker_no_client_create' },
    { value: 'broker_shared', label: 'broker_shared' },
    { value: 'nomaconseil', label: 'nomaconseil' },
    { value: 'norman_k', label: 'norman_k' },
    { value: 'lawyers', label: 'lawyers' },
    { value: 'wealth', label: 'wealth' },
    { value: 'immo', label: 'immo' },
]

const Form = observer(({ onSubmit, values }: FormProps<BrandFormValues>) => {
    const { t } = useTranslation()
    const { admin } = useMst()

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<BrandFormValues>({
        resolver: zodResolver(schema),
    })

    const hrEnabled = useWatch({ control, name: 'hrEnabled', defaultValue: false })
    const [hrAlreadyEnabled, setHrAlreadyEnabled] = useState<boolean>(false)

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values)) {
            setValue(key as keyof BrandFormValues, values[key])
        }

        setHrAlreadyEnabled(values.hrEnabled)
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="name"
                error={errors.name && `${t('web_admin_brand_name')} ${errors.name?.message}`}
                label={'web_admin_brand_name'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input {...register('name')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="url"
                error={errors.url && `${t('web_admin_brand_url')} ${errors.url?.message}`}
                label={'web_admin_brand_url'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input {...register('url')} type="text" className="w-1/3" defaultValue="https://" autoComplete="off" />
            </Label>
            <Label
                name="configFile"
                error={errors.configFile && `${t('web_admin_brand_config_file')} ${errors.configFile?.message}`}
                label={'web_admin_brand_config_file'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <Controller
                    name="configFile"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                options={configFiles.map(v => ({
                                    value: v.value,
                                    label: t(`web_admin_brand_config_file_${v.label}`),
                                }))}
                                onChange={file => onChange(file)}
                            />
                        )
                    }}
                />
            </Label>
            <Label
                name="hrEnabled"
                error={errors.hrEnabled && `${t('web_admin_brand_hr_enabled')} ${errors.hrEnabled?.message}`}
                label={'web_admin_brand_hr_enabled'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="hrEnabled"
                    render={({ field }) => (
                        <ToggleButton
                            tooltip={hrAlreadyEnabled ? t('web_admin_brand_hr_already_enabled') : undefined}
                            value={field.value}
                            onChange={enabled => {
                                if (!hrAlreadyEnabled) {
                                    field.onChange(enabled)
                                }
                            }}
                        />
                    )}
                />
            </Label>
            <Label
                name="hrCutFile"
                error={errors.hrCutFile && `${t('web_admin_brand_hr_cut_file')} ${errors.hrCutFile?.message}`}
                label={'web_admin_brand_hr_cut_file'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="hrCutFile"
                    render={({ field }) => (
                        <ToggleButton
                            value={field.value}
                            onChange={enabled => {
                                field.onChange(enabled)
                            }}
                        />
                    )}
                />
            </Label>
            <Label
                name="hrFilePattern"
                error={
                    errors.hrFilePattern && `${t('web_admin_brand_hr_file_pattern')} ${errors.hrFilePattern?.message}`
                }
                label={'web_admin_brand_hr_file_pattern'}
                className={clsx('my-5 flex w-full flex-wrap items-center', { hidden: !hrEnabled })}
                required={hrEnabled}
            >
                <input
                    {...register('hrFilePattern', {
                        value: 'PREFIXE_MARQUE_(?<lastname>.+)_(?<firstname>.+)_(?<month>[0-9]{2})_(?<year>[0-9]{4})\\.pdf',
                    })}
                    type="text"
                    className="w-3/4"
                    autoComplete="off"
                />
            </Label>
            <Label
                name="ocrJobTag"
                error={errors.ocrJobTag && `${t('web_admin_brand_ocr_job_tag')} ${errors.ocrJobTag?.message}`}
                label={'web_admin_brand_ocr_job_tag'}
                className={clsx('my-5 flex w-full flex-wrap items-center')}
            >
                <input {...register('ocrJobTag')} type="text" className="w-3/4" autoComplete="off" />
            </Label>
            <Label
                name="logo"
                error={errors.logo && `${t('web_admin_brand_logo')} ${errors.logo?.message}`}
                label={'web_admin_brand_logo'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <Controller
                    control={control}
                    name="logo"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="icon"
                error={errors.icon && `${t('web_admin_brand_icon')} ${errors.icon?.message}`}
                label={'web_admin_brand_icon'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <Controller
                    control={control}
                    name="icon"
                    render={({ field }) => (
                        <PictureUpload {...field} value={field.value} as={CropAs.URL} aspectRatio={1} />
                    )}
                />
            </Label>
            <Label
                name="color"
                error={errors.color && `${t('web_admin_brand_color')} ${errors.color?.message}`}
                label={'web_admin_brand_color'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <Controller
                    control={control}
                    name="color"
                    render={({ field }) => <ColorPicker color={field.value} onChange={hex => field.onChange(hex)} />}
                />
            </Label>
            <Label
                name="brandImage"
                error={errors.brandImage && `${t('web_admin_brand_image')} ${errors.brandImage?.message}`}
                label={'web_admin_brand_image'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="brandImage"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="brandLogo"
                error={errors.brandLogo && `${t('web_admin_brand_download_logo')} ${errors.brandLogo?.message}`}
                label={'web_admin_brand_download_logo'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <Controller
                    control={control}
                    name="brandLogo"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="viaColor"
                error={errors.viaColor && `${t('web_admin_brand_via_color')} ${errors.viaColor?.message}`}
                label={'web_admin_brand_via_color'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <Controller
                    control={control}
                    name="viaColor"
                    render={({ field }) => <ColorPicker color={field.value} onChange={hex => field.onChange(hex)} />}
                />
            </Label>

            <Label
                name="show_user_profile"
                error={
                    errors.show_user_profile &&
                    `${t('web_admin_brand_show_user_profile')} ${errors.show_user_profile?.message}`
                }
                label={'web_admin_brand_show_user_profile'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="show_user_profile"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>

            <Label
                name="show_models"
                error={errors.show_models && `${t('web_admin_brand_show_models')} ${errors.show_models?.message}`}
                label={'web_admin_brand_show_models'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="show_models"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>

            <Label
                name="secure_collaboration"
                error={
                    errors.secure_collaboration &&
                    `${t('web_admin_brand_secure_collaboration')} ${errors.secure_collaboration?.message}`
                }
                label={'web_admin_brand_secure_collaboration'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="secure_collaboration"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>

            <Label
                name="show_telecollecte"
                error={
                    errors.show_telecollecte &&
                    `${t('web_admin_brand_show_telecollecte')} ${errors.show_telecollecte?.message}`
                }
                label={'web_admin_brand_show_telecollecte'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="show_telecollecte"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>

            <Label
                name="admin_can_add_users"
                error={
                    errors.admin_can_add_users &&
                    `${t('web_admin_brand_admin_can_add_users')} ${errors.admin_can_add_users?.message}`
                }
                label={'web_admin_brand_admin_can_add_users'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="admin_can_add_users"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="show_crisp_button"
                error={
                    errors.show_crisp_button &&
                    `${t('web_admin_brand_show_crisp_button')} ${errors.show_crisp_button?.message}`
                }
                label={'web_admin_brand_show_crisp_button'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="show_crisp_button"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="disconnect_timeout"
                error={
                    errors.disconnect_timeout &&
                    `${t('web_admin_brand_disconnect_timeout')} ${errors.disconnect_timeout?.message}`
                }
                label={'web_admin_brand_disconnect_timeout'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="disconnect_timeout"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="role_admin_name"
                label={'web_admin_brand_role_admin_name'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input
                    {...register('role_admin_name')}
                    type="text"
                    className="w-1/3"
                    defaultValue={t('web_franchise_users_role_admin')}
                />
            </Label>
            <Label
                name="role_admin_controller"
                label={'web_admin_brand_role_admin_controller'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input
                    {...register('role_admin_controller')}
                    type="text"
                    className="w-1/3"
                    defaultValue={t('web_franchise_users_role_controller')}
                />
            </Label>
            <Label
                name="role_admin_franchisee"
                label={'web_admin_brand_role_admin_franchisee'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input
                    {...register('role_admin_franchisee')}
                    type="text"
                    className="w-1/3"
                    defaultValue={t('web_franchise_users_role_franchisee')}
                />
            </Label>
            <Label
                name="role_admin_agent"
                label={'web_admin_brand_role_admin_agent'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input
                    {...register('role_admin_agent')}
                    type="text"
                    className="w-1/3"
                    defaultValue={t('web_franchise_users_role_agent')}
                />
            </Label>
            <Label
                name="franchise_label"
                label={'web_admin_brand_franchise_label'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input
                    {...register('franchise_label')}
                    type="text"
                    className="w-1/3"
                    defaultValue={t('web_admin_brand_franchise')}
                />
            </Label>
            <Label
                name="client_label"
                label={'web_admin_brand_client_label'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input
                    {...register('client_label')}
                    type="text"
                    className="w-1/3"
                    defaultValue={t('web_admin_brand_client')}
                />
            </Label>
            <Label
                name="userPricing"
                error={errors.userPricing && `${t('web_admin_brand_user_pricing')} ${errors.userPricing?.message}`}
                label={'web_admin_brand_user_pricing'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required
                help="web_admin_brand_pricing_help"
            >
                <Controller
                    name="userPricing"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable
                                placeholder={t('web_admin_placeholder_select')}
                                options={[...admin.pricings].map(pricing => ({
                                    value: pricing.uuid,
                                    label: pricing.name,
                                }))}
                                onChange={pricing => onChange(pricing)}
                            />
                        )
                    }}
                />
            </Label>
            <Label
                name="partnerPricing"
                error={
                    errors.partnerPricing && `${t('web_admin_brand_partner_pricing')} ${errors.partnerPricing?.message}`
                }
                label={'web_admin_brand_partner_pricing'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required
                help="web_admin_brand_pricing_help"
            >
                <Controller
                    name="partnerPricing"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable
                                placeholder={t('web_admin_placeholder_select')}
                                options={[...admin.pricings].map(pricing => ({
                                    value: pricing.uuid,
                                    label: pricing.name,
                                }))}
                                onChange={pricing => onChange(pricing)}
                            />
                        )
                    }}
                />
            </Label>
            <div className="w-full text-right">
                <input type="submit" className="btn cursor-pointer" value={`${t('web_admin_brand_save')}`} />
            </div>
        </form>
    )
})

export default Form
