import Admin from 'components/admin/admin'
import Label from 'components/admin/label'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { useMst } from 'stores/store'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { optionValueSchema } from 'components/admin/forms'
import { z } from 'zod'

const schema = z.object({
    title: z.string().min(1),
    message: z.string().min(1).max(140),
    tags: z.array(optionValueSchema).min(1),
})
type Schema = z.infer<typeof schema>

const Create = () => {
    const { notificationCenter, partners } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()

    const [isLoading, setLoading] = useState<boolean>(false)

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Schema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    })

    const onSubmit = async (data: Schema) => {
        const { title, message, tags } = data

        setLoading(true)

        await notificationCenter.notifyUsers(
            title,
            message,
            tags.map(t => t.value)
        )

        reset()
        setLoading(false)
    }

    return (
        <Admin isLoading={isLoading} title={t('web_admin_notification_new')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label
                    name="title"
                    required
                    label={'web_admin_notification_title'}
                    className="my-5 flex w-full flex-wrap items-center"
                    error={errors['title'] && `${t('web_admin_notification_title')} ${errors['title']?.message}`}
                >
                    <input {...register('title')} type="text" className="w-1/3 " />
                </Label>
                <Label
                    name="message"
                    required
                    label={'web_admin_notification_message'}
                    className="my-5 flex w-full flex-wrap  items-center"
                    error={errors['message'] && `${t('web_admin_notification_message')} ${errors['message']?.message}`}
                >
                    <textarea {...register('message')} className="w-1/3 " rows={3} />
                </Label>
                <Label
                    name="tags"
                    required
                    label={'web_admin_notification_tags_select'}
                    className="my-5 flex w-full flex-wrap items-center"
                    error={errors['tags'] && `${t('web_admin_notification_tags_select')} ${errors['tags']?.message}`}
                >
                    <Controller
                        name="tags"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <AsyncSelect
                                {...field}
                                isMulti
                                isClearable
                                defaultOptions
                                className="w-1/3"
                                value={field.value}
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    const tags = await partners.loadTags(id, inputValue)

                                    return tags.map(tag => ({ value: tag.uuid, label: tag.name }))
                                }}
                            />
                        )}
                    />
                </Label>
                <input
                    type="submit"
                    disabled={!isValid}
                    className="btn float-right cursor-pointer"
                    value={`${t('web_admin_notification_cta')}`}
                />
            </form>
        </Admin>
    )
}

export default Create
