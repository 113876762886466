import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import PricingList from './pricing-list'

const PricingCta = observer(() => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-4 p-4">
            <h2 className="text-lg font-bold text-center">{t('web_me_choose_pricing')}</h2>
            <PricingList ctaContext />
        </div>
    )
})

export default PricingCta
