import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll'
import { ReactComponent as TelecollecteAdded } from '../../assets/telecollecte-added.svg'
import { getFromQuery } from '../../core/use-query'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import ContentPage from '../dataroom/content-page'
import Breadcrumb from '../shared/breadcrumb'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import SlidingPanel from './sliding-panel'

const Telecollecte = observer(() => {
    const { t } = useTranslation()
    const { telecollecte } = useMst()

    const search = getFromQuery('search')

    const connectionId = getFromQuery('connection_id')
    const [showModal, setShowModal] = useState<boolean>(connectionId !== undefined)

    const error = getFromQuery('error')

    useEffect(() => {
        const syncConnector = async () => {
            await telecollecte.getTypes()
            await telecollecte.syncConnectors()
        }
        syncConnector()
    }, [])

    useEffect(() => {
        if (!error) {
            return
        }

        toast('error', t('web_telecollecte_add_access_denied'))
    }, [error])

    return (
        <ContentPage title={t('web_telecollecte_title')}>
            <Breadcrumb>
                <Link to="/dataroom">{t('web_breadcrumb_dataroom')}</Link>
                <span>{t('web_telecollecte_title')}</span>
            </Breadcrumb>

            <Panel>
                <SlidingPanel title="web_telecollecte_active_account" isOpen isAvailable search={search} />

                {telecollecte.types.map(type => (
                    <SlidingPanel
                        key={type}
                        isAvailable
                        title="web_telecollecte_add_one"
                        isOpen
                        capabilities="document"
                        type={type}
                        search={search}
                    />
                ))}

                <Element name="soon" className=""></Element>
                <SlidingPanel isAvailable={false} title="web_telecollecte_soon" isOpen search={search} />
            </Panel>

            <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
                <TelecollecteAdded />
                <h2 className="mb-2 mt-4 text-xl font-bold">{t('web_telecollecte_sync_success_title')}</h2>
                <h3 className="font-bold ">{t('web_telecollecte_sync_success_info')}</h3>
                <p className="my-6 text-center text-sm text-pale-sky">{t('web_telecollecte_sync_success_desc')}</p>
                <button className="text-pale-sky underline" onClick={() => setShowModal(false)}>
                    {t('web_telecollecte_sync_success_close')}
                </button>
            </Modal>
        </ContentPage>
    )
})

export default Telecollecte
