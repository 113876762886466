import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { z } from 'zod'
import { useMst } from '../../../stores/store'
import { Input, selectStyles } from '../../shared/select-styles'
import Admin from '../admin'
import { optionValueSchema } from '../forms'
import Label from '../label'

export const schema = z.object({
    title: z.string().min(1),
    message: z.string().max(140),
    partners: z.array(optionValueSchema).min(1),
})
type Schema = z.infer<typeof schema>

const Create = () => {
    const { notificationCenter, admin } = useMst()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(false)

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Schema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    })

    const load = async () => {
        setLoading(true)

        await admin.loadPartners(1000, 0)

        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    const onSubmit = async (data: Schema) => {
        const { title, message, partners } = data

        setLoading(true)

        await notificationCenter.notifyPartners(
            title,
            message,
            partners.map(p => p.value)
        )

        reset()
        setLoading(false)
    }

    return (
        <Admin isLoading={isLoading} title={t('web_admin_notification_new')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label
                    name="title"
                    required
                    label={'web_admin_notification_title'}
                    className="my-5 flex w-full flex-wrap items-center"
                    error={errors['title'] && `${t('web_admin_notification_title')} ${errors['title']?.message}`}
                >
                    <input {...register('title')} type="text" className="w-1/3 " />
                </Label>
                <Label
                    name="message"
                    required
                    label={'web_admin_notification_message'}
                    className="my-5 flex w-full flex-wrap  items-center"
                    error={errors['message'] && `${t('web_admin_notification_message')} ${errors['message']?.message}`}
                >
                    <textarea {...register('message')} className="w-1/3 " rows={3} />
                </Label>
                <Label
                    name="partners"
                    required
                    label={'web_admin_notification_partners_select'}
                    className="my-5 flex w-full flex-wrap  items-center"
                    error={
                        errors['partners'] &&
                        `${t('web_admin_notification_partners_select')} ${errors['partners']?.message}`
                    }
                >
                    <Controller
                        name="partners"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Select
                                    styles={selectStyles}
                                    components={{ Input }}
                                    className="w-1/3"
                                    value={value}
                                    isClearable
                                    isMulti
                                    placeholder={t('web_admin_placeholder_select')}
                                    options={[...admin.partners].map(partner => ({
                                        value: partner.uuid,
                                        label: partner.name,
                                    }))}
                                    onChange={partner => onChange(partner)}
                                />
                            )
                        }}
                    />
                </Label>
                <input
                    type="submit"
                    disabled={!isValid}
                    className="btn float-right cursor-pointer"
                    value={`${t('web_admin_notification_cta')}`}
                />
            </form>
        </Admin>
    )
}

export default Create
