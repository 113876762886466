import { zodResolver } from '@hookform/resolvers/zod'
import { Input, selectStyles } from 'components/shared/select-styles'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import { z } from 'zod'
import Label from '../../admin/label'
import ToggleButton from '../../shared/toggle-button'
import 'components/admin/zod'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import localeFr from 'air-datepicker/locale/fr'
import DatePicker from 'components/admin/date-picker'
import { optionValueSchema } from 'components/admin/forms'
import { getTypes } from 'components/admin/users/form'
import { get } from 'core/services/http-service'
import AsyncCreatableSelect from 'react-select/async-creatable'

const schema = z.object({
    firstname: z.string(),
    lastname: z.string(),
    email: z.string().email(),
    secondaryEmail: z.union([z.literal(''), z.string().email()]),
    birthdate: z.coerce.date().optional(),
    partnerId: z.string(),
    role: optionValueSchema,
    type: optionValueSchema,
    phone: z.string().optional().nullish(),
    confirmUserIntegration: z.boolean().nullish(),
    tags: z.array(optionValueSchema).optional(),
})
export type FormSchema = z.infer<typeof schema>
type Key = keyof FormSchema

const Form = ({
    onSubmit,
    values,
    showMessage = false,
    showAllRoles = true,
    brandId,
}: {
    onSubmit: (data: FormSchema) => Promise<void>
    values?: FormSchema
    showMessage?: boolean
    showAllRoles?: boolean
    brandId?: string
}) => {
    const { t } = useTranslation()
    const { user, brand } = useMst()

    const currentLocale = localeFr
    const names = user.currentFranchise.brandConfig?.get('roles')

    const [showConfirmUserIntegration, setShowConfirmUserIntegration] = useState<boolean>(false)

    const roles = [
        { value: 'ADMIN', label: names?.get('admin') ?? t('web_franchise_users_role_admin') },
        { value: 'CONTROLLER', label: names?.get('controller') ?? t('web_franchise_users_role_controller') },
        { value: 'FRANCHISEE', label: names?.get('franchisee') ?? t('web_franchise_users_role_franchisee') },
        { value: 'AGENT', label: names?.get('agent') ?? t('web_franchise_users_role_agent') },
    ].filter(role => showAllRoles || ['ADMIN', 'CONTROLLER'].includes(role.value))

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<FormSchema>({
        resolver: zodResolver(schema),
    })

    const types = getTypes(t)
    const email = watch('email')
    const confirmUserIntegration = watch('confirmUserIntegration')

    useEffect(() => {
        if (values || !email || email.length === 0) {
            // edit form
            return
        }

        get<void, { data: { exists: boolean } }>(`/v1/bo/users/${email}/exists`).then(({ data }) => {
            const { exists } = data
            setShowConfirmUserIntegration(exists)
        })
    }, [email])

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values)) {
            let value = values[key]
            if (key === 'role') {
                value = roles.find(role => role.value === value)
            }
            setValue(key as Key, value)
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="firstname"
                error={errors['firstname'] && `${t('web_admin_user_firstname')} ${errors['firstname']?.message}`}
                label={'web_admin_user_firstname'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input {...register('firstname')} type="text" className="w-1/3" required />
            </Label>
            <Label
                name="lastname"
                error={errors['lastname'] && `${t('web_admin_user_lastname')} ${errors['lastname']?.message}`}
                label={'web_admin_user_lastname'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input {...register('lastname')} type="text" className="w-1/3" required />
            </Label>

            <Label
                name="email"
                error={errors['email'] && `${t('web_admin_user_email')} ${errors['email']?.message}`}
                label={'web_admin_user_email'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input {...register('email')} type="text" className="w-1/3" required />
            </Label>
            <Label
                name="secondaryEmail"
                error={
                    errors['secondaryEmail'] &&
                    `${t('web_admin_user_secondary_email')} ${errors['secondaryEmail']?.message}`
                }
                label={'web_admin_user_secondary_email'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <input {...register('secondaryEmail')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="partnerId"
                error={errors['partnerId'] && `${t('web_admin_user_partner_id')} ${errors['partnerId']?.message}`}
                label={'web_admin_user_partner_id'}
                className="my-5 flex w-full flex-wrap items-center"
                required
            >
                <input {...register('partnerId')} type="text" className="w-1/3" required />
            </Label>
            <Label
                name="type"
                error={errors['type'] && `${t('web_admin_user_person_type')} ${errors['type']?.message}`}
                label={'web_admin_user_person_type'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required
            >
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                options={types}
                                onChange={type => onChange(type)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="phone"
                error={errors['phone'] && `${t('web_admin_user_phone')} ${errors['phone']?.message}`}
                label={'web_admin_user_phone'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <input {...register('phone')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="birthdate"
                error={errors['birthdate'] && `${t('web_admin_user_birthdate')} ${errors['birthdate']?.message}`}
                label={'web_admin_user_birthdate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
            >
                <Controller
                    name="birthdate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} />
                    }}
                />
            </Label>

            <Label
                name="role"
                error={errors['role'] && `${t('web_admin_user_role')} ${errors['role']?.message}`}
                label={'web_admin_user_role'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required
            >
                <Controller
                    name="role"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                options={roles}
                                onChange={role => onChange(role)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="tags"
                error={errors['tags'] && `${t('web_partner_invite_tag')} ${errors['tags']?.message}`}
                label={'web_partner_invite_tag'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                {brandId ? (
                    <Controller
                        name="tags"
                        control={control}
                        render={({ field }) => (
                            <AsyncCreatableSelect
                                {...field}
                                className="w-1/3"
                                value={field.value}
                                defaultOptions
                                loadOptions={async (inputValue: string) => {
                                    const tags = await brand.loadTags(brandId, inputValue)

                                    return tags.map(tag => ({
                                        value: tag.uuid,
                                        label: tag.name,
                                    }))
                                }}
                                isClearable
                                isMulti
                                onCreateOption={async (inputValue: string) => {
                                    const tag = await brand.createTag(brandId, inputValue)
                                    field.onChange([...(field.value ?? []), { value: tag.uuid, label: tag.name }])
                                }}
                            />
                        )}
                    />
                ) : (
                    <FontAwesomeIcon icon={faSpinner} spin />
                )}
            </Label>

            {showConfirmUserIntegration ? (
                <>
                    <p>{t('web_franchise_user_already_exists')}</p>
                    <Controller
                        name="confirmUserIntegration"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return <ToggleButton value={value} onChange={onChange} />
                        }}
                    />
                </>
            ) : (
                showMessage && <p>{t('web_franchise_new_user_info_email')}</p>
            )}
            <input
                type="submit"
                disabled={showConfirmUserIntegration && !confirmUserIntegration}
                className="btn float-right cursor-pointer"
                value={`${t('web_admin_user_save')}`}
            />
        </form>
    )
}

export default Form
