import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { hasBrandAccess } from 'stores/brands'
import type { BackupFile } from 'stores/files'
import validator from 'validator'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import { passwordRegex } from '../../stores/users'
import Panel from '../shared/panel'
import SecurePassword from '../shared/secure-password'
import Backup from './backup'
import ProfilePage from './profile-page'

const Security = observer(() => {
    const { t } = useTranslation()
    const { user, paymentManager, files } = useMst()

    const [backup, setBackup] = useState<BackupFile>()

    const [oldPassword, setOldPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')

    const [saving, setSaving] = useState<boolean>(false)

    const savePasswordEnabled = [
        !saving,
        !validator.isEmpty(oldPassword),
        !validator.isEmpty(newPassword),
        validator.matches(newPassword, passwordRegex),
        oldPassword !== newPassword,
        newPassword === newPasswordConfirmation,
    ].reduce((previous, current) => previous && current, true)

    const saveNewPassword = async () => {
        setSaving(true)

        const valid = await user.modifyPassword(oldPassword, newPassword)
        setSaving(false)
        if (valid) {
            toast('success', 'web_me_password_saved')
        }
    }

    const checkForBackup = async () => {
        await files.checkForBackup()
        if (files.backup && files.backup.status === 'none') {
            setBackup(null)

            return
        }
        setBackup(files.backup)
    }

    const getBackup = async () => {
        await files.postBackup()
        await checkForBackup()
        toast('success', 'web_me_backup_waiting')
    }

    useEffect(() => {
        checkForBackup()
    }, [checkForBackup])

    useEffect(() => {
        paymentManager.getStatus()
    }, [paymentManager])

    return (
        <ProfilePage url="/me/security">
            <Panel className="mt-4" innerClassName="flex flex-col items-center bg-white">
                {user.ssoMethod === '' && (
                    <div className="mb-8 flex w-full flex-col md:w-2/3">
                        <h2 className="text-lg font-bold">{t('web_me_security_connection')}</h2>

                        <div className="my-4">
                            <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                <div className="w-full md:w-1/2">
                                    <h3 className="mb-2 text-lg">{t('web_me_security_change_password')}</h3>
                                    <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                        {t('web_me_security_change_password_desc')}
                                    </span>
                                </div>
                                <div className="flex w-full flex-col space-y-2 md:w-1/2">
                                    <SecurePassword
                                        disabled={saving}
                                        value={oldPassword}
                                        onChange={value => setOldPassword(value)}
                                        placeholder={t('web_me_security_old_password')}
                                        autoComplete="current-password"
                                    />
                                    <SecurePassword
                                        disabled={saving}
                                        value={newPassword}
                                        onChange={value => setNewPassword(value)}
                                        placeholder={t('web_me_security_new_password')}
                                        autoComplete="new-password"
                                    />
                                    <SecurePassword
                                        disabled={saving}
                                        value={newPasswordConfirmation}
                                        onChange={value => setNewPasswordConfirmation(value)}
                                        placeholder={t('web_me_security_confirm_password')}
                                        autoComplete="new-password"
                                    />

                                    <button
                                        type="button"
                                        disabled={!savePasswordEnabled}
                                        className="btn white"
                                        onClick={() => saveNewPassword()}
                                    >
                                        {t('web_me_security_password_confirm')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="mb-8 flex w-full flex-col md:w-2/3">
                    <h2 className="text-lg font-bold">{t('web_me_security_two_factor')}</h2>

                    <div className="my-4">
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <div className="w-full md:w-1/2">
                                <h3 className="mb-2 text-lg">{t('web_me_security_use_two_factor')}</h3>
                                <span className="pr-2 text-regent-gray">
                                    {t('web_me_security_use_two_factor_desc')}
                                </span>
                            </div>
                            <div className="flex w-full flex-col space-y-2 md:w-1/2">
                                <Link className="btn white" to="/me/security/twofa">
                                    {t('web_me_security_use_two_factor_go')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {hasBrandAccess(user, 'show_user_profile') && (
                    <div className="mb-8 flex w-full flex-col md:w-2/3">
                        <h2 className="text-lg font-bold">{t('web_me_profile_download_treasy_title')}</h2>

                        <div className="my-4">
                            <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                <div className="w-full pr-2 text-regent-gray md:w-1/2">
                                    <Trans i18nKey="web_me_profile_download_treasy_desc">
                                        web_me_profile_download_treasy_desc
                                    </Trans>
                                </div>
                                <div className="flex w-full flex-col md:w-1/2">
                                    {backup ? (
                                        <Backup backup={backup} />
                                    ) : (
                                        <>
                                            {user.canManagePersonalData ? (
                                                <button
                                                    className="btn white w-full"
                                                    onClick={() => getBackup()}
                                                    type="button"
                                                >
                                                    {t('web_me_profile_download_treasy')}
                                                </button>
                                            ) : (
                                                <button disabled className="btn white" type="button">
                                                    {t('web_me_profile_download_treasy')}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Panel>
        </ProfilePage>
    )
})

export default Security
