import { faCalendar, faSquareCheck as faRegularSquareCheck, faSquare } from '@fortawesome/pro-regular-svg-icons'
import {
    faCircleCheck,
    faCircleXmark,
    faPen,
    faSpinner,
    faSquareCheck,
    faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tab } from '@headlessui/react'
import AirDatepicker from 'air-datepicker'
import localeFr from 'air-datepicker/locale/fr'
import ViaTreasy from 'assets/via-treasy'
import { clsx } from 'clsx'
import Modal from 'components/shared/modal'
import SmallLoader from 'components/shared/small-loader'
import Tooltip from 'components/shared/tooltip'
import Config from 'core/config'
import { once, trigger } from 'core/events'
import { get, post } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { Fragment, useEffect, useRef, useState } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { Trans, useTranslation } from 'react-i18next'
import type { ChecklistStepFile, Client } from 'stores/brands'
import { useMst } from 'stores/store'
import { ReactComponent as UploadZen } from '../../assets/login/register-zen.svg'
import FileIcon from './upload/file-icon'
import { getExtension } from './upload/mime-types'

interface FilenameProps {
    file: ChecklistStepFile
    onEdit: (file: ChecklistStepFile) => void
    onDelete: (file: ChecklistStepFile) => void
    onCompletionChange: (completion: number, totalCompletion: number) => void
    readOnly: boolean
}
const Filename = ({ file, onCompletionChange, onEdit, onDelete, readOnly }: FilenameProps) => {
    const { t } = useTranslation()
    const { files, user } = useMst()
    const [loadingValidate, setLoadingValidate] = useState<boolean>(false)
    const [loadingConcerned, setLoadingConcerned] = useState<boolean>(false)
    const [loadingChangeDate, setLoadingChangeDate] = useState<boolean>(false)

    const [isOcr, setIsOcr] = useState<boolean>(false)
    const [completedAt, setCompletedAt] = useState<DateTime | undefined>()
    const [notConcerned, setNotConcerned] = useState<boolean>(false)

    const $input = useRef<HTMLInputElement>()
    const dp = useRef<AirDatepicker>()

    const update = (newFile: ChecklistStepFile) => {
        if (newFile.date && newFile.date !== '') {
            const date = DateTime.fromISO(newFile.date)
            if (date.isValid) {
                const jsDate = date.toJSDate()
                dp.current?.selectDate(jsDate, { silent: true })
            }
        }
        setIsOcr(newFile.isOcr)
        setCompletedAt(
            newFile.completedAt && newFile.completedAt !== '' ? DateTime.fromISO(newFile.completedAt) : undefined
        )
        setNotConcerned(newFile.notConcerned)
    }

    const changeFileDate = async (date: Date) => {
        if (loadingChangeDate) {
            return
        }

        setLoadingChangeDate(true)
        const { file: newFile, completion, totalCompletion } = await files.saveFileDate(file, date)
        update(newFile)
        setLoadingChangeDate(false)
        onCompletionChange(completion, totalCompletion)
    }

    useEffect(() => {
        dp.current = new AirDatepicker($input.current, {
            locale: localeFr,
            autoClose: true,
            timepicker: false,
            onSelect: ({ date }) => changeFileDate(date as Date),
        })

        return () => {
            try {
                dp.current?.destroy()
            } catch (e) {
                //
            }
        }
    }, [])

    const [showDelete, setShowDelete] = useState<boolean>(false)

    const linkFilesToChecklistStepFile = async (file: ChecklistStepFile, objects: { uuid: string }[]) => {
        const data = {
            files: objects.map(({ uuid }) => uuid),
        }

        setLoadingValidate(true)
        await post<typeof data, void>(`/v1/web/checklist/step-file/${file.uuid}/files`, data)
        toast('success', t('web_checklist_file_completed'))
        setLoadingValidate(false)
    }

    const [showUploadWarning, setShowUploadWarning] = useState<boolean>(false)
    const [showFileChoiceModal, setShowFileChoiceModal] = useState<boolean>(false)
    const [checklistFileChoice, setChecklistFileChoice] = useState<'upload' | 'stack'>()
    const [checklistStepFile, setChecklistStepFile] = useState<ChecklistStepFile>()

    type StackFile = { uuid: string; name: string }

    const [loadingStack, setLoadingStack] = useState<boolean>(false)
    const [stack, setStack] = useState<StackFile[]>([])

    const getStack = async () => {
        setLoadingStack(true)
        const { data } = await get<void, { data: { stack: StackFile[] } }>('/v1/web/stack')
        setStack(data.stack)
        setLoadingStack(false)
    }

    const confirmFileChoice = async (file: StackFile) => {
        const conf = confirm(t('web_checklist_file_choice_confirm', { file: file.name }))
        if (!conf) {
            return
        }

        await linkFilesToChecklistStepFile(checklistStepFile, [{ uuid: file.uuid }])
        setChecklistStepFile(undefined)
        setShowFileChoiceModal(false)
        setChecklistFileChoice(undefined)
    }

    useEffect(() => {
        if (checklistFileChoice === 'stack') {
            getStack()
        }
    }, [checklistFileChoice])

    useEffect(() => {
        if (checklistFileChoice) {
            setShowFileChoiceModal(false)

            switch (checklistFileChoice) {
                case 'upload':
                    listenForUploadedFiles(checklistStepFile)
                    trigger('uploader:trigger')
                    break
                case 'stack':
                    //
                    break
            }
        }
    }, [checklistFileChoice, checklistStepFile])

    const listenForUploadedFiles = (file: ChecklistStepFile) => {
        once(
            'uploader:uploaded_objects',
            async (
                event: CustomEvent<{
                    objects: { uuid: string; spaceUuid: string; directoryUuid: string; name: string }[]
                }>
            ) => {
                if (event.detail.objects.length === 0) {
                    await onToggleValidate(file)

                    setShowUploadWarning(true)
                    setChecklistFileChoice(undefined)
                    setChecklistStepFile(undefined)

                    return
                }

                await linkFilesToChecklistStepFile(
                    file,
                    event.detail.objects.map(({ uuid }) => ({ uuid }))
                )
            }
        )
    }

    const onToggleValidate = async (file: ChecklistStepFile): Promise<void> => {
        if (loadingValidate) {
            return
        }

        setLoadingValidate(true)
        const {
            file: newFile,
            completion,
            totalCompletion,
            fileChoiceRequired,
        } = await files.toggleChecklistFile(file, 'validated')

        update(newFile)
        setLoadingValidate(false)
        onCompletionChange(completion, totalCompletion)

        if (Config.app.VOUSFINANCER_V3_ENABLED && fileChoiceRequired && newFile.completedAt) {
            setShowFileChoiceModal(true)
            setChecklistStepFile(newFile)
        }
    }

    const resetFileChoices = async () => {
        setChecklistStepFile(undefined)
        setShowFileChoiceModal(false)
        setChecklistFileChoice(undefined)
        await onToggleValidate(checklistStepFile)
    }

    const onToggleConcerned = async (file: ChecklistStepFile): Promise<void> => {
        if (loadingConcerned) {
            return
        }

        setLoadingConcerned(true)
        const { file: newFile, completion, totalCompletion } = await files.toggleChecklistFile(file, 'concerned')

        update(newFile)
        setLoadingConcerned(false)
        onCompletionChange(completion, totalCompletion)
    }

    useEffect(() => {
        update(file)
    }, [file])

    return (
        <>
            {checklistFileChoice === 'stack' && (
                <Modal
                    bottomAlignment="center"
                    isOpen
                    onRequestClose={async () => await resetFileChoices()}
                    overflowHidden={false}
                    overlayClassName="z-50"
                >
                    <UploadZen className="-mt-36" />
                    <div className="mt-12 flex flex-col gap-4 items-center">
                        {loadingStack ? (
                            <SmallLoader />
                        ) : (
                            <div className="flex flex-col gap-2">
                                {stack.length > 0 ? (
                                    stack.map(file => (
                                        <button
                                            onClick={async () => await confirmFileChoice(file)}
                                            type="button"
                                            key={file.uuid}
                                            className="flex items-center gap-2 hover:text-atomic-tangerine"
                                        >
                                            <FileIcon extension={getExtension(file.name)} className="w-5" />
                                            <span>{file.name}</span>
                                        </button>
                                    ))
                                ) : (
                                    <p>{t('web_checklist_stack_empty')}</p>
                                )}
                            </div>
                        )}
                        <button
                            type="button"
                            className="hover:text-atomic-tangerine"
                            onClick={() => {
                                setChecklistFileChoice(undefined)
                                setShowFileChoiceModal(true)
                            }}
                        >
                            {t('web_checklist_back')}
                        </button>
                    </div>
                </Modal>
            )}
            <Modal
                bottomAlignment="center"
                isOpen={showUploadWarning}
                onRequestClose={() => {}}
                onConfirm={() => setShowUploadWarning(false)}
                overlayClassName="z-50"
            >
                <h3 className="mt-12 font-bold">{t('web_checklist_step_upload_file_title')}</h3>
                <p className="mt-2">{t('web_checklist_step_upload_file_message')}</p>
            </Modal>
            <Modal
                bottomAlignment="center"
                isOpen={showFileChoiceModal}
                onRequestClose={async () => await resetFileChoices()}
                overflowHidden={false}
                overlayClassName="z-50"
            >
                <UploadZen className="-mt-36" />
                <div className="flex flex-col gap-4 items-center mt-12">
                    <h3 className="font-bold text-center">{t('web_checklist_file_choice_prompt')}</h3>
                    <button type="button" onClick={() => setChecklistFileChoice('upload')} className="btn">
                        {t('web_checklist_file_choice_upload')}
                    </button>
                    <button type="button" onClick={() => setChecklistFileChoice('stack')} className="btn">
                        {t('web_checklist_file_choice_stack')}
                    </button>
                    <button
                        type="button"
                        className="hover:text-atomic-tangerine"
                        onClick={async () => {
                            await resetFileChoices()
                        }}
                    >
                        {t('web_checklist_cancel')}
                    </button>
                </div>
            </Modal>
            <div className="flex items-center justify-between">
                <div className="flex items-start gap-4 pr-2">
                    <Tooltip
                        tooltip={t(
                            notConcerned
                                ? 'web_checklist_not_concerned'
                                : completedAt
                                  ? 'web_checklist_invalidate'
                                  : 'web_checklist_validate'
                        )}
                    >
                        <button
                            type="button"
                            className="relative"
                            onClick={() => onToggleValidate(file)}
                            disabled={notConcerned}
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={
                                    loadingValidate
                                        ? faSpinner
                                        : completedAt
                                          ? isOcr
                                              ? faRegularSquareCheck
                                              : faSquareCheck
                                          : faSquare
                                }
                                spin={loadingValidate}
                                className={clsx('fill-current', {
                                    'text-heather': !completedAt || isOcr,
                                    'text-yellow-green': completedAt && !isOcr,
                                    'cursor-not-allowed opacity-40': notConcerned,
                                    'cursor-pointer': !notConcerned && !loadingValidate,
                                })}
                            />
                            {isOcr && !loadingValidate && (
                                <span className="absolute -bottom-0.5 -right-1 flex h-3 w-3 items-center justify-center rounded-full bg-yellow-green text-[0.5rem] font-bold text-white">
                                    A
                                </span>
                            )}
                        </button>
                    </Tooltip>
                    <div
                        className={clsx('checklist-item', {
                            'text-inner-space': !notConcerned,
                            'text-heather': notConcerned,
                        })}
                    >
                        <div dangerouslySetInnerHTML={{ __html: file.name }} />
                    </div>
                </div>

                <div className="flex items-center justify-center gap-2">
                    {file.options?.hasDate && (
                        <>
                            <input
                                ref={$input}
                                type="text"
                                className={clsx('m-0 w-28 border-0 p-0', {
                                    'cursor-pointer text-inner-space': !notConcerned,
                                    'bg-white text-heather': notConcerned,
                                })}
                                disabled={loadingChangeDate || notConcerned}
                            />
                            <FontAwesomeIcon
                                fixedWidth
                                icon={loadingChangeDate ? faSpinner : faCalendar}
                                spin={loadingChangeDate}
                                className={clsx({
                                    'cursor-pointer text-inner-space': !notConcerned,
                                    'text-heather': notConcerned,
                                })}
                                onClick={() => $input.current?.focus()}
                            />
                        </>
                    )}
                    {!readOnly && !showDelete && (
                        <Tooltip tooltip={t(notConcerned ? 'web_checklist_concerned' : 'web_checklist_not_concerned')}>
                            <FontAwesomeIcon
                                fixedWidth
                                onClick={() => onToggleConcerned(file)}
                                icon={loadingConcerned ? faSpinner : notConcerned ? faCircleCheck : faCircleXmark}
                                spin={loadingConcerned}
                                className={clsx('fill-current text-heather', {
                                    'cursor-pointer': !loadingConcerned,
                                })}
                            />
                        </Tooltip>
                    )}
                    {file.options?.userDefined && (
                        <>
                            {showDelete ? (
                                <>
                                    <Tooltip tooltip={t('web_checklist_delete_confirm')}>
                                        <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            fixedWidth
                                            className={clsx('cursor-pointer fill-current text-sushi')}
                                            onClick={() => onDelete(file)}
                                        />
                                    </Tooltip>
                                    <Tooltip tooltip={t('web_checklist_delete_cancel')}>
                                        <FontAwesomeIcon
                                            icon={faCircleXmark}
                                            fixedWidth
                                            className={clsx('cursor-pointer fill-current text-bittersweet')}
                                            onClick={() => setShowDelete(false)}
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Tooltip tooltip={t('web_checklist_edit')}>
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            fixedWidth
                                            className={clsx('cursor-pointer fill-current text-heather')}
                                            onClick={() => onEdit(file)}
                                        />
                                    </Tooltip>
                                    <Tooltip tooltip={t('web_checklist_delete')}>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            fixedWidth
                                            className={clsx('cursor-pointer fill-current text-heather')}
                                            onClick={() => setShowDelete(true)}
                                        />
                                    </Tooltip>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

interface ChecklistProps {
    client: Client

    isOpen: boolean
    onClose: () => void

    onCompletionChange: (completion: number) => void
}

const Checklist = ({ client: baseClient, isOpen, onClose, onCompletionChange }: ChecklistProps) => {
    const { t } = useTranslation()
    const { user, files } = useMst()

    const [pdfGeneration, setPdfGeneration] = useState<boolean>(false)
    const [pdf, setPdf] = useState<string>()

    const [showAddLine, setShowAddLine] = useState<boolean>(false)
    const [lineValue, setLineValue] = useState<string>()
    const [lineId, setLineId] = useState<string>()
    const [saving, setSaving] = useState<boolean>(false)

    const [client, setClient] = useState<Client>(baseClient)

    const [completion, setCompletion] = useState<{ position: number; completion: number }[]>([])

    const [readOnly, setReadOnly] = useState<boolean>(false)
    type ChecklistStep = (typeof client.checklist.steps)[number]

    useEffect(() => {
        const completion = client.checklist.steps.map(step => ({
            position: step.position,
            completion: step.completion,
        }))
        setCompletion(completion)

        if (Config.app.VOUSFINANCER_V3_ENABLED && client.checklist) {
            setReadOnly(client.checklist.readOnly)
        }
    }, [client])

    const changeCompletion = (position: number, completion: number, totalCompletion: number) => {
        setCompletion(prev => {
            const newCompletion = [...prev]
            const index = newCompletion.findIndex(c => c.position === position)
            newCompletion[index].completion = completion

            return newCompletion
        })
        onCompletionChange(totalCompletion)
    }

    const downloadPdf = async () => {
        if (client.checklist.pdf && client.checklist.pdf !== '') {
            setPdf(client.checklist.pdf)

            return
        }

        setPdfGeneration(true)
        const pdf = await files.generatePdf(client.checklist.uuid)
        setPdfGeneration(false)
        setPdf(pdf)
    }

    const saveLine = async (stepUuid: string) => {
        setSaving(true)
        const { client } = await files.addChecklistStepLine(stepUuid, lineValue, lineId)
        setClient(client)
        setSaving(false)
        setShowAddLine(false)
        setLineValue(undefined)
    }

    const deleteLine = async (stepUuid: string, lineUuid: string) => {
        setSaving(true)
        const { client } = await files.deleteChecklistStepLine(stepUuid, lineUuid)
        setClient(client)
        setSaving(false)
    }

    return (
        <Modal isOpen={isOpen} size="10/12" height="h-[90%]" maxHeight="90%" onRequestClose={() => {}} padding={4}>
            <div className="flex h-full w-full flex-col gap-2">
                <div className="flex w-full">
                    <div className={clsx('flex flex-col items-end p-1')}>
                        <div className="flex grow justify-start">
                            <img
                                src={user.currentFranchise.logo}
                                className="m-auto max-h-20"
                                alt={user.currentFranchise.name}
                            />
                        </div>
                        <div className="flex grow justify-end">
                            <ViaTreasy className="text-[#2c2a2c]" />
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center gap-2">
                        <h1 className="text-center text-lg font-bold">{client.name}</h1>
                        <h2 className="cursor-pointer text-center font-bold underline" onClick={() => downloadPdf()}>
                            <Trans i18nKey="web_checklist_download">
                                s <span className="text-bittersweet">s</span> et
                            </Trans>
                        </h2>
                        {pdfGeneration && (
                            <h3 className="flex items-center justify-center gap-4 text-center text-sm">
                                <span>{t('web_checklist_download_wait')}</span>
                                <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
                            </h3>
                        )}
                        {pdf && (
                            <h3 className="flex items-center justify-center gap-4 text-center text-sm">
                                <a href={pdf} target="_blank" rel="noreferrer">
                                    {t('web_checklist_download_file')}
                                </a>
                            </h3>
                        )}
                    </div>
                </div>
                <div className="flex h-full w-full grow flex-col gap-6 p-4">
                    <Tab.Group>
                        <Tab.List className="flex w-full justify-between">
                            {client.checklist.steps
                                .sort((a, b) => a.position - b.position)
                                .map(item => (
                                    <Tab key={item.uuid} as={Fragment}>
                                        {({ selected }) => (
                                            <div className="flex grow cursor-pointer items-center gap-4 font-nunito">
                                                <Tooltip
                                                    tooltip={t('web_checklist_step_percent', {
                                                        percent:
                                                            completion.find(c => c.position === item.position)
                                                                ?.completion ?? 0,
                                                    })}
                                                >
                                                    <div className={clsx('h-10 w-10')}>
                                                        <CircularProgressbarWithChildren
                                                            value={
                                                                completion.find(c => c.position === item.position)
                                                                    ?.completion ?? 0
                                                            }
                                                            styles={{
                                                                path: {
                                                                    stroke: '#a7e47f',
                                                                },
                                                                trail: { stroke: 'transparent' },
                                                            }}
                                                        >
                                                            <div className="h-full w-full p-1">
                                                                <div
                                                                    className={clsx(
                                                                        'flex h-full w-full shrink-0 items-center justify-center rounded-full font-bold',
                                                                        {
                                                                            'bg-outer-space text-white': selected,
                                                                            'bg-heather text-white':
                                                                                !selected && item.completion !== 100,
                                                                            'bg-yellow-green text-white':
                                                                                !selected && item.completion === 100,
                                                                        }
                                                                    )}
                                                                >
                                                                    {item.options?.hideNumber
                                                                        ? item.name[0]
                                                                        : item.position}
                                                                </div>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                </Tooltip>
                                                <span
                                                    className={clsx('font-extrabold', {
                                                        'text-outer-space': selected,
                                                        'text-heather': !selected && item.completion !== 100,
                                                        'text-yellow-green': !selected && item.completion === 100,
                                                    })}
                                                >
                                                    {item.name}
                                                </span>
                                            </div>
                                        )}
                                    </Tab>
                                ))}
                        </Tab.List>
                        <Tab.Panels className="grow">
                            {client.checklist.steps
                                .sort((a, b) => a.position - b.position)
                                .map(item => (
                                    <Tab.Panel key={item.uuid} as="div" className="flex h-full w-full flex-col gap-8">
                                        <ul
                                            className="px-4 grid grid-cols-2 gap-2"
                                            style={{
                                                columnFill: 'auto',
                                            }}
                                        >
                                            {item.files
                                                .sort((a, b) => a.position - b.position)
                                                .map(file => (
                                                    <li key={file.uuid} className="pl-1 pr-12">
                                                        <Filename
                                                            readOnly={readOnly}
                                                            file={file}
                                                            onCompletionChange={(completion, totalCompletion) =>
                                                                changeCompletion(
                                                                    item.position,
                                                                    completion,
                                                                    totalCompletion
                                                                )
                                                            }
                                                            onDelete={file => deleteLine(item.uuid, file.uuid)}
                                                            onEdit={file => {
                                                                setLineId(file.uuid)
                                                                setLineValue(file.name)
                                                                setShowAddLine(true)
                                                            }}
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                        {!readOnly && (
                                            <div className="flex w-full justify-end gap-2">
                                                {showAddLine ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={lineValue}
                                                            placeholder={t('web_checklist_add_line_value')}
                                                            onChange={e => setLineValue(e.target.value)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn px-8"
                                                            onClick={() => saveLine(item.uuid)}
                                                            disabled={
                                                                saving || !lineValue || lineValue.trim().length === 0
                                                            }
                                                        >
                                                            {t('web_checklist_add_element_save')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn white px-8"
                                                            onClick={() => setShowAddLine(false)}
                                                            disabled={saving}
                                                        >
                                                            {t('web_checklist_add_element_cancel')}
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn px-8"
                                                        onClick={() => setShowAddLine(true)}
                                                    >
                                                        {t('web_checklist_add_element')}
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </Tab.Panel>
                                ))}
                        </Tab.Panels>
                    </Tab.Group>
                    <div className="flex w-full flex-col items-center justify-center gap-4">
                        <div className="flex w-full items-center justify-center gap-4">
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faSquareCheck}
                                    className="fill-current text-yellow-green"
                                />
                                <span className="text-sm font-bold">{t('web_checklist_legend_file_valid')}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="relative">
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={faRegularSquareCheck}
                                        className="fill-current text-heather"
                                    />
                                    <span className="absolute -bottom-0.5 -right-1 flex h-3 w-3 items-center justify-center rounded-full bg-yellow-green text-[0.5rem] font-bold text-white">
                                        A
                                    </span>
                                </div>
                                <span className="text-sm font-bold">{t('web_checklist_legend_ocr_file')}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon fixedWidth icon={faSquare} className="fill-current text-heather" />
                                <span className="text-sm font-bold">{t('web_checklist_legend_missing_file')}</span>
                            </div>
                            {!readOnly && (
                                <div className="flex items-center gap-2">
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={faCircleXmark}
                                        className="fill-current text-heather"
                                    />
                                    <span className="text-sm font-bold">{t('web_checklist_legend_not_concerned')}</span>
                                </div>
                            )}
                        </div>
                        <button type="button" className="btn px-8" onClick={() => onClose()}>
                            {t('web_checklist_close')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Checklist
